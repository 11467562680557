import MasarAddress from "./MasarAdress"
import SocialLinks from "./SocialLinks"
import ServiesLinks from "./ServiesLinks"
import GeneralLinks from "./GeneralLinks"

// for social Links and Address section in footer
function ColumnOne() {
    return (
        <div className="col-9 col-sm-5 col-md-4 col-lg-3">
            <MasarAddress />
            <SocialLinks />
        </div>
    )
};


// for Services Links section in footer
function ColumnTwo() {
    return (
        <ServiesLinks />
    )
};

// for General Links section in footer
function ColumnThree() {
    return (
        <GeneralLinks />
    )
};

export { ColumnOne, ColumnTwo, ColumnThree };