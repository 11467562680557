import { Link } from "react-router-dom";
import ServiesData from '../../../data/ServiesData.json';


export default function ServiesLinks() {
    return (
        <ul className='d-none d-lg-block col-5 col-lg-3'>
            {ServiesData.map((service, index) => (
                <li key={index} className='footer-links'>
                    <Link to={service.link} className='nav-link p-1' aria-current="page" >
                        {service.title}</Link>
                </li>
            ))}
        </ul>
    )
};
