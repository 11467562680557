import BooksSlider from "./components/BooksSlider"
import SeeMoreLink from "../../components/common/SeeMoreLink"
import LatestBooksTitle from "./components/LatestBooksTitle"

export default function LatestBooks() {

    return (
        <div className="container py-4 mt-4">
            <div className="d-flex justify-content-between align-items-start">
                <LatestBooksTitle />
                <SeeMoreLink linkTo="/masarLibrary" linkValue="المزيد" />
            </div>
            <BooksSlider />
        </div>
    )
}
