import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginForm from './components/LoginForm';

export default function LogIn() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (Cookies.get('token')) {
      navigate("/")
    }
  }, [navigate]);


  return (
    <div className='dashboard bg-light-blue col-12 position-relative'>
      <div className='bg-white h-50'></div>
      {loading ?
        <div className="loader"></div>
        :
        <div className='dashboard-form container col-10 col-md-6 px-4 py-5 text-start h-50 bg-dark-orange rounded-4 border border-2 shadow border-white position-absolute top-50 start-50 translate-middle'>
          <h4 className='text-white'>تسجيل الدخول</h4>
          <LoginForm
            setLoading={setLoading}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </div>
      }

      <div className='d-none d-sm-block col-6 bg-light-blue'>
      </div>
    </div>

  )
}
