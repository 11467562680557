import UserSideBar from "../../layouts/UserSideBar";
import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../hooks/apiFetch';
import moment from 'moment';
// import useCountdownTimer from "../../helpers/useCountdownTimer"
import Pagination from "../../utilities/Pagination"
import { SearchInput } from "../../components/search/Search";
import { allPaymentStatus, allServicesCategories, allSubServices } from "../../data/CategoriesData";
// import { UserPayDetials } from "../UserPayDetials"

export default function MyPayments() {
    // const [requestData, setRequestData] = useState(null);
    // const [allRequestsData, setAllRequestsData] = useState(null);
    // const [allPaidData, setAllPaidData] = useState(null);
    const [paidData, setPaidData] = useState(null);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    // const [userApproval, setUserApproval] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPaymentStatus, setCurrentPaymentStatus] = useState(null);
    // const [pageLimit, setPageLimit] = useState(5);
    const [profile, setProfile] = useState(null);
    // const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null)



    // function handleUserApproval() {
    //     setUserApproval(true);
    //     //update userApproval to true
    // }
    // function handleUserReject() {
    //     setUserApproval(false)
    // }
    const totalPages = paidData?.data?.paginationInfo.totalPagesCount;
    const userName = profile?.data?.firstName;



    //Search Query filtering 
    const filteredPaidReqests = paidData?.data?.result.filter(data =>
        data.orderType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // data.status.toLowerCase().includes(searchQuery) ||
        // data.id.toString().includes(searchQuery) ||
        // data.createdAt.toString().includes(searchQuery) ||
        data.amount.toString().includes(searchQuery)
        // data.orderDetails?.service.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // console.log(paidData?.data?.result[0].orderDetails);


    useEffect(() => {
        async function fetchRequests() {
            try {
                const profileData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                const dataPaid = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/payment/my-payments?${searchQuery ? "" : `page=${currentPage}&limit=5}${currentPaymentStatus ? `&status=${currentPaymentStatus}` : ""}`}`);
                // const allData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/my-orders`);
                // const data = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/my-orders?page=${currentPage}&limit=5`);

                // const allDataPaid = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/payment/my-payments`);

                setProfile(profileData);
                // setRequestData(data);
                // setAllRequestsData(allData)

                setPaidData(dataPaid)
                // setPageLimit(paidData?.data?.totalCount)
                // setAllPaidData(allDataPaid)

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }
        fetchRequests();
    }, [currentPage, currentPaymentStatus, searchQuery]);


    // const dataRequests = requestData?.data?.result;
    // console.log(moment(orderCreatedAt).format());
    // console.log((paidData?.data?.result).length);


    // const { time, reset, stop, start, isRunning } = useCountdownTimer(2 * 60 * 60);

    // const formatTime = (seconds) => {
    //     const hrs = Math.floor(seconds / 3600);
    //     const mins = Math.floor((seconds % 3600) / 60);
    //     const secs = seconds % 60;
    //     return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    // };

    // default reply hours
    // const addHours = 48;

    // const currentDate = new Date(orderCreatedAt).getTime();
    // const createTime = new Date(orderCreatedAt).getTime();
    // const updatedTime = new Date(createTime + addHours * 60 * 60 * 1000).getTime();


    const Payments = ({ index, orderId, serviceName, orderType, numberOfForms, language, orderDescription, orderCreated, orderUpdated, orderAmount, subServices, orderStatus, isUserApproved, offerStatus, offerFile, offerNote, customerFile, orderDetails }) => (
        <>

            <tr
            // className={`${orderStatus === "Pending"
            //     && "table-warning"} ${orderStatus === "Completed"
            //     && "table-success"}`}
            >

                <td className="col" > {`${moment(orderCreated).format('YY')}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`}</td>
                <td className="col-2">
                    {moment(orderCreated).format('DD-MM-YYYY')}</td>
                <td className="col-5">{orderType === "services" ? "خدمات" : "كتب"}
                    <br />
                    {orderType === "services" ?
                        (<span className="text-info">
                            {allServicesCategories.map(cat => cat.en === orderDetails[0].service ? cat.ar : "")}</span>) : ""}
                    {/* <span
                        className="text-decoration-underline c-lightBlue"
                        data-bs-toggle="modal"
                        data-bs-target={`#user-pay-details-${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`}>
                        التفاصيل</span> */}
                </td>
                <td className="col">{new Intl.NumberFormat("ar-SA", { style: 'currency', currency: 'SAR' }).format(
                    orderAmount,)}
                    {/* <UserPayDetials
                        orderId={orderId}
                        numberOfForms={numberOfForms}
                        language={language}
                        orderDescription={orderDescription}
                        subServices={subServices}
                        orderStatus={orderStatus}
                        orderType={orderType}
                        serviceName={serviceName}
                        offerFile={offerFile}
                        offerNote={offerNote}
                        orderAmount={orderAmount}
                        customerFile={customerFile}
                        orderDetails={orderDetails}
                    /> */}
                </td>
                {/* <td className="col-2 text-center">
                    <button className={`d-none btn-masar btn-dark-orange mx-1 p-2 pb-1`}
                        value={offerFile}
                        onClick={offerFile && handlePDFClick}>
                        <FontAwesomeIcon icon={faFileLines} />
                    </button>
                    <button className="d-none btn-masar btn-light-blue mx-1 p-2 pb-1"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="إعادة الطلب"
                    // onClick="{handleRepeatOrder}"
                    >
                        <FontAwesomeIcon icon={faRepeat} /></button>


            </td> */}
            </tr>

        </>
    );



    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!paidData) {
        return <div className="loader"></div>;
    }
    moment.locale('en');

    return (
        <div className='container dashboard col-12 position-relative d-flex'>
            <div className='container col-1 col-md-2 m-0 text-start'>
                <UserSideBar />
            </div>
            <div className='container mt-4 col-11 col-md-10'>
                <h6 className="text-md-end m-0 c-darkOrange text-center"><span className="text-black">أهلا/</span>
                    {userName}
                </h6>
                <div className="container mt-3 rounded h-75 bg-dark-blue">
                    {!(paidData?.data?.result).length ?
                        // {(paidData?.data?.result).length ?
                        (<p className="text-white text-center py-5">لا يوجد مدفوعات حتى الآن
                            <br />
                            <br />
                            <a className="btn-masar btn-dark-orange text-decoration-none p-2" href="/services">انتقل للخدمات</a>
                        </p>)
                        : (
                            <div className="d-flex flex-wrap justify-content-center ">
                                {filteredPaidReqests !== "" &&
                                    <SearchInput
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                    />
                                }

                                <div className="container mt-3 rounded bg-light-gray position-relative p-3 overflow-auto">
                                    <div className="form-floating">
                                        <select className="form-select form-select-sm w-75 mb-2"
                                            id="select-user-status"
                                            onChange={e => setCurrentPaymentStatus(e.target.value)}>
                                            <option value="">الكل</option>
                                            {allPaymentStatus.map((oneStatus, index) =>
                                                <option key={index} value={oneStatus.en}>{oneStatus.ar}</option>
                                            )}
                                        </select>
                                        <label htmlFor="select-user-status">حالة الدفع</label>
                                    </div>

                                    {filteredPaidReqests !== "" ?
                                        <table className="table table-hover">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th scope="col">الكود</th>
                                                    <th scope="col">التاريخ</th>
                                                    <th scope="col">بيان الدفع</th>
                                                    <th scope="col">التكلفة</th>
                                                    {/* <th scope="col"></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {dataRequests.map((request, index) => ( */}
                                                {/* {filteredPaidReqests.map((request, index) => ( */}
                                                {(searchQuery ? filteredPaidReqests : paidData?.data?.result).map((request, index) => (
                                                    <Payments
                                                        key={index}
                                                        orderType={request.orderType}
                                                        orderId={request.id}
                                                        serviceName={
                                                            allServicesCategories.map(cat => cat.en === request.service ? cat.ar : "")}
                                                        orderCreated={request.createdAt}
                                                        orderUpdated={request.updatedAt}
                                                        orderStatus={allPaymentStatus.map(state => state.en === request.status ? state.ar : "")}
                                                        numberOfForms={request.numberOfForms}
                                                        language={request.language}
                                                        orderDescription={request.description}

                                                        subServices={request.subServices &&
                                                            request.subServices.map((e, index) => {
                                                                const matchedSubService = allSubServices.find(subServ => subServ.en === e);
                                                                return matchedSubService ? (index > 0 ? " - " : "") + matchedSubService.ar : "";
                                                            }).filter(Boolean) // This removes any empty strings
                                                        }

                                                        offerFile={request.offerDetails?.file}
                                                        orderAmount={request.amount}
                                                        orderCreatedAt={request.createdAt}
                                                        offerNote={request.offerDetails?.notes}
                                                        customerFile={request.file}
                                                        orderDetails={request.orderDetails}

                                                    />

                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                        <p className="text-center mt-2">لا يوجد مدفوعات حتى الآن</p>
                                    }

                                </div>
                                {totalPages > 1 &&
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages} />
                                }
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
};



