import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';
import SubmitButton from '../../../components/buttons/SubmitButton';

export default function LoginForm({ setLoading, setErrorMessage, errorMessage }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    async function onSubmit(data) {
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/auth/login`, {
                method: "POST",
                headers: {
                    "accept": "*/*",
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.status === 401 || response.status === 400) {
                setErrorMessage("هناك خطأ في بيانات الدخول")
                return;
            }

            const responseData = await response.json();
            const token = responseData.data.accessToken;

            // Save token to localStorage
            Cookies.set('token', token, { expires: 7, secure: true });

            // Redirect to another page after successful login
            navigate('/');
        }
        catch (error) {
            console.error("An error occurred during login", error);
            setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className='d-flex flex-column mx-auto my-5 gap-3 text-start' onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
                register={register}
                errors={errors}
            />

            <InputPassword
                register={register}
                errorMessage={errorMessage}
            />

            <Link className='text-white'
                to='/resetPassword'
            >نسيت كلمة المرور؟</Link>

            <p>ليس لديك حساب على مسار؟
                <br />
                <Link className='text-white text-decoration-none' to="/Register">أنشئ حساب</Link>
            </p>
            <SubmitButton
                value="دخول"
                type="submit"
            />


        </form>
    )
}
