// import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../../../hooks/apiFetch';
import moment from 'moment';

import Pagination from "../../../utilities/Pagination"
import { allPaymentStatus, allServicesCategories, allStatusListItems } from "../../../data/CategoriesData"

export function SettingPayments() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null)
    const [serviceData, setServiceData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [allServiceData, setAllServiceData] = useState(null);
    const [pageLimit, setPageLimit] = useState(5);

    const orderStatusList = ["Pending", "Rejected", "Approved"];
    const offerStatusList = ["Pending", "Rejected", "Approved"];

    const totalPages = paymentData?.data?.paginationInfo.totalPagesCount;

    const paymentStatus = ["Pending", "Completed", "Failed"]
    // moment.locale('en');
    // const date = moment(OrdersData[0].result[0].createdAt).format('Do MM YYYY');


    const AdminOrder = ({ index, paymentId, paymentType, serviceId, numberOfForms, totalPay, orderDescription, orderCreated, orderUser, orderImg, paymentStatus, orderStatusEn, paymentAmount }) => (
        <>
            <tr key={index}
                className={`${orderStatusEn === "Rejected"
                    && "table-danger"} ${orderStatusEn === "Quotation Process"
                    && "table-warning"} align-content-center`}>
                <td className="col" > {`${moment(orderCreated).format('YY')}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 2 }).format(
                    paymentId,)}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(paymentId,)}`
                }</td>
                <td className="col-2">
                    {moment(orderCreated).format('DD-MM-YYYY')}</td>
                <td className="col">{paymentType === "services" ? "خدمات" : "كتب"}</td>
                <td className="col"><img className="small-profile-image me-1" crossOrigin="anonymous" src={orderImg} />{orderUser}</td>
                <td className="col">{new Intl.NumberFormat("ar-SA", { style: 'currency', currency: 'SAR' }).format(
                    paymentAmount,)}</td>
                <td className="col">{allPaymentStatus.find(item => item.en === paymentStatus)?.ar || ""}</td>

            </tr>
        </>);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // const filteredServiceData = allServiceData?.data?.result.filter(item =>
    //     item.user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.offerDetails?.price.toString().includes(searchQuery) ||
    //     item.offerDetails?.status.toLowerCase().includes(searchQuery) ||
    //     item.createdAt.includes(searchQuery)
    // );

    const filteredPaymentData = paymentData?.data?.result.filter(item =>
        // item.orderType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        // item.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.amount.toString().includes(searchQuery) ||
        item.createdAt.includes(searchQuery)
    );


    const handleModiratorClick = (id) => {
        setSelectedPayment(id);
    };


    useEffect(() => {
        async function fetchServices() {
            try {
                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                // const allData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins`);
                // const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/orders/admins?${selectedStatus ? `status=${selectedStatus}&` : ""}page=${currentPage}&limit=5`);
                const payment = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/payment/system-payments?${selectedPaymentStatus ? `status=${selectedPaymentStatus}&` : ""}${searchQuery ? "" : "page=" + currentPage + "&"}limit=${searchQuery ? pageLimit : 5}`);

                setProfile(profileData);
                // setServiceData(data);
                setPaymentData(payment);
                setPageLimit(payment?.data?.paginationInfo.totalCount)
                // setAllServiceData(allData);

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchServices();
    }, [currentPage, selectedStatus, selectedPaymentStatus, searchQuery]);

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!paymentData) {
        return <div className="loader"></div>;
    }

    moment.locale('en');

    // console.log(paymentData?.data?.paginationInfo.totalCount);

    return (

        <div className='container position-relative d-flex col-11 col-md-10 m-0'>
            <div className='container mt-4'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3 ">
                    <div className="d-flex gap-1 flex-wrap justify-content-center ">
                        <input
                            name="queryPayment"
                            className="form-control-sm border-0 w-auto"
                            type="search"
                            placeholder="بحث"
                            value={searchQuery}
                            onChange={handleSearchChange} />

                        <div className="container position-relative p-3 overflow-auto">
                            <div className="form-floating">
                                <select className="form-select form-select-sm w-75 mb-2"
                                    id="select-status"
                                    onChange={e => setSelectedPaymentStatus(e.target.value)}>
                                    <option value="">الكل</option>
                                    {allPaymentStatus.map((oneStatus, index) =>
                                        <option key={index} value={oneStatus.en}>{oneStatus.ar}</option>
                                    )}
                                </select>
                                <label htmlFor="select-status">حالة الدفع</label>
                            </div>


                            <table className="table table-hover align-middle">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">كود</th>
                                        <th scope="col">التاريخ</th>
                                        <th scope="col">نوع الدفع</th>
                                        <th scope="col">العميل</th>
                                        <th scope="col">المبلغ</th>
                                        <th scope="col">الحالة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchQuery ? filteredPaymentData : paymentData)
                                        .data?.result.map((order) => (
                                            order.amount > 1 &&
                                            <AdminOrder
                                                key={order.id}
                                                paymentId={order.id}
                                                orderCreated={order.createdAt}
                                                paymentType={order.orderType}
                                                orderUser={order.user.firstName}
                                                orderImg={order.user.image}
                                                orderUserID={order.user.id}
                                                paymentStatus={order.status}
                                                numberOfForms={order.numberOfForms}
                                                language={order.language}
                                                orderDescription={order.description}
                                                subServices={order.subServices}
                                                offerStatus={allStatusListItems.map(oneStatus => oneStatus.en === order.offerDetails?.status ? oneStatus.ar : "")}
                                                paymentAmount={order.amount}

                                            />

                                        ))}
                                </tbody>
                            </table>

                        </div>
                        {totalPages > 1 &&
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
