import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { allBooksCategories, allProfilesCategories } from "../data/CategoriesData"
import { editModiratorsData, editBooksform, deleteBooksform } from "../hooks/apiAxios"
// import { apiFetchAdmin } from '../helpers/apiFetch';
import SuccessErrorMsg from "../utilities/SuccessErrorMsg"
import { handleImageChange, handleBookImageChange } from "../utilities/handleFunctions/HandelImageChange"
import { emailValidation, mobileValidation } from "../utilities/validations/inputsValidations"
import { toast, ToastContainer } from "react-toastify";

export function ModiratorData({ id, firstName, lastName, email, mobilePhone, role, userImage }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [image, setImage] = useState(userImage);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);

    const roles = ["superAdmin", "admin", "moderator"]

    async function onSubmit(formData) {
        setLoading(true);

        try {
            const response = await editModiratorsData(id, formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }


    return (
        <>

            <form className='d-flex shadow mx-auto gap-2 text-start bg-white pe-5 ps-3 pb-4 rounded'
                onSubmit={handleSubmit(onSubmit)}>


                <div className="profile-image-container position-relative my-auto ms-2">
                    <img className="profile-image mx-auto"
                        crossOrigin="anonymous"
                        src={image ? image : '../images/user0-img.jpg'} alt={`${role}_${id}_Img`} />
                    <h6 className="profile-change-text border text-center">تغيير الصورة</h6>

                    <input
                        // disabled
                        className=" opacity-0 profile-image-input"
                        type="file"
                        accept="image/jpeg"
                        onChange={(e) => handleImageChange(e, setValue, setImage,
                            setErrorMessage)}
                    />
                </div>

                <div className="d-flex flex-column col-8 mx-auto my-5 gap-3">


                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الاسم الأول</span>
                        <input className={`form-control ${errors.firstName && "border border-danger"}`}
                            type="text"
                            placeholder="الاسم الأول"
                            onChange={(e) => { setValue("firstName", e.target.value) }}
                            defaultValue={firstName}
                            {...register("firstName", { required: true, minLength: 3 })} />
                        {errors.firstName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                    </div>
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الاسم الأخير</span>
                        <input className={`form-control ${errors.lastName && "border border-danger"}`}
                            type="text"
                            placeholder="الاسم الأخير"
                            onChange={(e) => { setValue("lastName", e.target.value) }}
                            defaultValue={lastName}
                            {...register("lastName", { required: true, minLength: 3 })} />
                        {errors.lastName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >البريد</span>
                        <input className='form-control' type="text" placeholder="البريد الالكتروني" defaultValue={email} {...register("email", { required: true, pattern: emailValidation })} />
                        {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >رقم الجوال</span>
                        <input className='form-control text-start' type="tel" placeholder="05xxxxxxxx" defaultValue={mobilePhone} {...register("mobilePhone", { required: true, pattern: mobileValidation })} />
                        {errors.mobilePhone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >المسؤولية</span>
                        <select className='form-select text-start'>
                            <option value={role}>{role}</option>
                            {roles
                                .filter(oneRole => oneRole !== role)
                                .map((oneRole, index) => (
                                    <option key={index} value={oneRole}>
                                        {oneRole}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >إعادة تعيين كلمة السر</span>
                        <input className='form-control'
                            type="password" placeholder="كلمةالسر"
                            name='password'
                            onChange={e => setValue("password", e.target.value)}

                        // {...register("password", { minLength: 8, maxLength: 16 })}
                        />
                        {errors.password && <p className='errors-msg'>كلمة المرور تكون من ٨-١٠ أحرف</p>}
                    </div>
                </div>
                <button
                    className='btn-masar btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                    type="submit">
                    {loading &&
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                    }
                    تعديل
                </button>


            </form>

            {
                errorMessage &&
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {errorMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            }

            {
                dataUpdate &&
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    تم تحديث ملفك بنجاح .. شكرا لك
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            }
        </>
    )
}



export function BookDataModify({ id, title, img, imgAlt, price, description, qty, available, author, pubYear, bookCategory, pages }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [image, setImage] = useState(img);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [dataUpdate, setDataUpdate] = useState(false);
    const [loading, setLoading] = useState(false);


    const thisYear = new Date().getFullYear();

    async function onSubmit(formData) {
        setLoading(true);
        console.log(formData);

        try {
            const response = await editBooksform(id, formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                console.log(formData);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.data.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);

        }
    }

    async function deleteBookBtn(id) {
        setLoading(true);

        try {
            const response = await deleteBooksform(id);

            if (response.data.isSuccess) {
                // setDataUpdate(true);
                toast.success("تم تعديل البيانات ..شكرا لك")
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                setErrorMessage(toast.error("حدث خطأ ما، حاول مرة أخرى"), response.data.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            switch (error.response.status) {
                case 413:
                    setErrorMessage(toast.warn("حجم الصورة كبير"));
                    break;
                case 400:
                    setErrorMessage(toast.warn("تأكد من إدخال جميع البيانات بالشكل الصحيح"));
                    break;
                default:
                    setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
            }

            // setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
        } finally {
            setLoading(false);

        }
    }

    return (
        <div>
            <form encType="multipart/form-data" method="patch"
                className='d-flex flex-column flex-md-row shadow mx-auto gap-2 text-start bg-white pe-5 ps-3 pb-4 rounded align-items-center'
                onSubmit={handleSubmit(onSubmit)}>

                <div className="book-image-container position-relative m-auto mt-3 my-md-auto ms-md-3">
                    <img className="book-image mx-auto"
                        crossOrigin="anonymous"
                        src={image ? image : "../images/BookSample-1.png"} alt={imgAlt}
                    // src={!image ? img : image} alt={imgAlt} 

                    />
                    <h6 className="profile-change-text border text-center">تغيير الصورة</h6>

                    <input
                        className="opacity-0 profile-image-input"
                        type="file"
                        accept="image/jpeg"
                        onChange={(e) => handleBookImageChange(e, setValue, setImage)}
                    />
                </div>


                <div className="d-flex flex-column col-11 col-md-9 ms-4 mb-5 my-md-5 gap-2">

                    {/* Input fields */}

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الكتاب</span>
                        <input
                            className={`form-control ${errors.title && "border border-danger"}`}
                            type="text"
                            defaultValue={title}
                            // onChange={(e) => { setValue("title", e.target.value) }}
                            {...register("title", { required: true, minLength: 10 })}
                        />
                        {errors.title && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >المؤلف</span>
                        <input
                            className='form-control'
                            type="text"
                            defaultValue={author}
                            {...register("author", { required: true })} />
                        {errors.author && <p className='errors-msg'>أدخل المؤلف</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >السعر</span>
                        <input
                            className='form-control text-start'
                            type="number"
                            defaultValue={price}
                            {...register("price", { required: true, valueAsNumber: true })} />
                        <span className="input-group-text" >ريال</span>
                        {errors.price && <p className='errors-msg'> أدخل السعر الصحيح</p>}
                        <span className="input-group-text" >الكمية</span>
                        <input
                            className='form-control text-start'
                            type="number"
                            defaultValue={qty}
                            {...register("quantity", { valueAsNumber: true })} />
                        {errors.qty && <p className='errors-msg'> أدخل الكمية</p>}
                        <span className="input-group-text" >الصفحات</span>
                        <input
                            className='form-control text-start'
                            type="number"
                            defaultValue={pages}
                            {...register("numberOfPages", { valueAsNumber: true })} />
                        {errors.pages && <p className='errors-msg'> أدخل الصفحات</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >التصنيف</span>
                        <select className='form-select text-start'>
                            <option value={bookCategory}>{bookCategory}</option>
                            {allBooksCategories
                                .filter(category => category !== bookCategory)
                                .map((category, index) => (
                                    <option key={index} value={category.en}>
                                        {category.ar}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >متوفر</span>
                        <select className='form-select text-start'
                            {...register("available")}
                            defaultValue={available}
                        // onChange={e => setValue("available", e.target.value)}
                        >
                            <option value={true}>متوفر</option>
                            <option value={false}>طباعة نسخة خاصة</option>
                        </select>
                        <span className="input-group-text" >تاريخ النشر</span>
                        <input
                            className={`form-control border`}
                            type="number"
                            defaultValue={pubYear}
                            {...register("publishedDate", { required: true, pattern: /^\d{4}$/, min: 1980, max: { thisYear }, valueAsNumber: true })} />
                        {errors.pubYear && <p className='errors-msg'> تاريخ النشر أكبر من ١٩٨٠م</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الوصف</span>
                        <textarea
                            className={`form-control ${errors.title && "border border-danger"}`}
                            type="textArea"
                            rows="7"
                            onChange={(e) => { setValue("description", e.target.value) }}
                            defaultValue={description}
                            {...register("description", { required: true, minLength: 10 })} />
                        {errors.description && <p className='errors-msg'> مطلوب أكبر من ١٠ أحرف</p>}
                    </div>


                    <p className='notice-font bg-warning p-1 rounded'>إذا كانت الكمية (صفر) سيتم اخفاء الكتاب للعرض</p>
                </div>
                <button
                    className='btn btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                    type="submit">
                    {loading &&
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                    }
                    تعديل
                </button>
                <button
                    className='btn btn-light-blue text-white col-2 ms-3 mb-3 position-absolute bottom-0 start-0' onClick={() => deleteBookBtn(id)}>
                    {loading &&
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                    }
                    حذف
                </button>

                {/* <input className='btn btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0 ' type="submit" value="تعديل" /> */}


            </form>
            <SuccessErrorMsg
                ToastContainer={ToastContainer}
            // errorMessage={errorMessage}
            // dataUpdate={dataUpdate}
            // successMessage={"تم تعديل البيانات ..شكرا لك"}
            />
        </div>
    )
}


export function ProfileModify({ id, imgSrc, imgCat, description }) {
    return (
        <div id={`profile-works-details-${id}`}
            className="modal fade"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="profileDetailsLabel"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="profileDetailsLabel">التفاصيل</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <img crossOrigin="anonymous" className='w-50 rounded my-2 text-center'
                            src={imgSrc ? imgSrc : "/images/Profile-Sample0.jpg"} />
                        <div className="mt-2">
                            <label>التصنيف</label>
                            <select className='form-select form-select-sm' >
                                <option value={imgCat}>{imgCat}</option>
                                {allProfilesCategories
                                    .filter(category => category.ar !== imgCat)
                                    .map((category, index) => (
                                        <option key={index} value={category.en}>
                                            {category.ar}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="w-100 mt-2">
                            <label htmlFor="offerFile">ملف جديد</label>
                            <input className="form-control form-control-sm mb-4" type="file" id="offerFile" accept=".pdf" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
