import AdminAccount from "./AdminAccount";
import HeaderLogo from "./components/HeaderLogo";
import "./Header.css"

export default function AdminHeader() {
  return (
    <header className="py-0 bg-light-gray">
      <div className="container-md d-flex justify-content-between">
        <HeaderLogo />
        <AdminAccount />
      </div>
    </header>
  );
}
