import { emailValidation } from '../../../utilities/validations/inputsValidations';

export default function InputEmail({ register, errors }) {
    return (
        <>
            <input
                className='form-control'
                type="text"
                placeholder="البريد الالكتروني"
                {...register("email", { required: true, pattern: emailValidation })} />
            {errors.email && <p className='errors-msg bg-warning p-1 rounded'>
                تأكد من إدخال البريد الإلكتروني الصحيح
            </p>}
        </>
    )
}

