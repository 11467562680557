import AdminPageLink from "./AdminPageLink"
import AppVersion from "./AppVersion"

export default function CopyRight() {
    const copyDate = new Date().getFullYear();
    return (
        <p className="text-center copy-rights border-0 mb-0">
            جميع الحقوق محفوظة لمسار التميز © {copyDate}
            <AppVersion />
            <AdminPageLink />
        </p>
    );
}