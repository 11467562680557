import Error404Img from "../assets/images/404Error.gif"


export default function NotFound() {
    return (
        <div className="text-center">
            <img src={Error404Img} className="NotFound-404" alt="Error 404" />
        </div>
    )

}