import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { SampleNextArrow, SamplePrevArrow } from "./SliderSampleArrows";
import BookContainer from "../../../components/common/BookContainer";

export default function BooksSlider() {
    const navigate = useNavigate();
    const [libraryBooks, setLibraryBooks] = useState([]);
    const [error, setError] = useState(null);

    const handleBookClick = (bookId) => {
        async function fetchLibrary() {
            try {
                const book = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books/${bookId}`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const bookByID = await book.json();

                navigate(`/masarLibrary/${bookByID?.data?.id}`);

            } catch (err) {
                setError("هناك مشكلة في جلب الكتاب");
            }
        }

        fetchLibrary();

    };


    const settings = {
        className: "center",
        centerPadding: "60px",
        dots: true,
        infinite: true,
        speed: 4000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        initialSlide: 0,
        swipeToSlide: true,
        rtl: true,
        pauseOnHover: true,
        autoplaySpeed: 6000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    nextArrow: null,
                    prevArrow: null,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            }
        ]
    };


    useEffect(() => {
        async function fetchLibrary() {
            try {
                const booksData = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?limit=50`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const responseData = await booksData.json();


                setLibraryBooks(responseData);


            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchLibrary();
    }, []);


    return (
        <div className="slider-container container text-center">
            <Slider {...settings}>
                {libraryBooks?.data?.result
                    .filter(book => book.category?.type !== 'Children')
                    .slice(0, 10)
                    .map((item, index) => (
                        <BookContainer
                            handleBookClick={handleBookClick}
                            item={item}
                            index={index} />
                    ))}
            </Slider>
            {error && <p className="text-center text-warning">{error}</p>}

        </div>
    );

}