import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import Cookies from 'js-cookie';

export default function HeaderCart() {
    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        }
    }, [isLogIn]);

    return (
        <>
            <FontAwesomeIcon icon={faCartShopping} className="user-cart text-white bg-light-blue" type='button' id="cartDropdown" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />
            {isLogIn &&
                <ul className={`dropdown-menu dropdown-menu-end text-end bg-med-gray border-white`} aria-labelledby="cartDropdown">
                    <li>
                        <Link className="dropdown-item" to="/myBasket">إلى السلة</Link>
                    </li>
                </ul>
            }
        </>
    )
};



