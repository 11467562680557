export default function AboutSection(props) {
    return (

        <div className="container d-flex justify-content-between align-items-start flex-wrap text-center text-md-start">
            <h5 className="col-12 col-md-2 mt-2 c-darkBlue text-md-end">
                {props.headName}
            </h5>
            <p className="col-12 col-md mt-3 mt-md-5 px-5 ps-md-0 lh-lg">{props.sectionName}</p>
        </div>
    )
};