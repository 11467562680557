import { Link } from "react-router-dom";


export default function MasarAddress() {
    return (
        <>
            <Link to="/contact-us">
                <h6>تواصل معنا</h6>
            </Link>
            <address className="pt-2 pb-2">
                ٢٨٨٩، طريق الأمير سعد بن عبدالرحمن الأول
                <br />
                الرياض - المملكة العربية السعودية
            </address>
        </>
    )

}