import showIcon from "../assets/icons/ico-Shows.png"
import advIcon from "../assets/icons/ico-adv.png"
import bannersIcon from "../assets/icons/ico-banners.png"
import bookIcon from "../assets/icons/ico-books.png"
import identityIcon from "../assets/icons/ico-identity.png"
import libIcon from "../assets/icons/ico-lib.png"
import packageIcon from "../assets/icons/ico-package.png"
import photoIcon from "../assets/icons/ico-photo.png"
import profileIcon from "../assets/icons/ico-profile.png"
import socialIcon from "../assets/icons/ico-social.png"
import webIcon from "../assets/icons/ico-web.png"
import wasaelIcon from "../assets/icons/ico-wasael.png"

import showImg from "../assets/images/servShows-img.jpg"
import advImg from "../assets/images/servAdv-img.jpg"
import bannersImg from "../assets/images/servBanner-img.jpg"
import bookImg from "../assets/images/servBooks-img.jpg"
import identityImg from "../assets/images/servIdentity-img.jpg"
import libImg from "../assets/images/servLib-img.jpg"
import packageImg from "../assets/images/servPackage-img.jpg"
import photoImg from "../assets/images/servPhoto-img.jpg"
import profileImg from "../assets/images/servProfile-img.jpg"
import socialImg from "../assets/images/servSocial-img.jpg"
import webImg from "../assets/images/servWeb-img.jpg"

const ServiceData = [
    {
        id: 1,
        title: "الكتب",
        description: "أفضل ما يمكن أن تتركه علمٌ وهبه الله لك، والكتاب كلمات من نور تضيء لك ولمن حولك بما وهبك الله من علم أو معلومة صادقة تفيد بها من حولك وتكون أفضل أثر لك في الدنيا والآخرة",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servBooks",
        imgSrc: bookImg,
        imgAlt: "Books services",
        icon: bookIcon,
        status: "active",
    },
    {
        id: 2,
        title: "المواقع والتطبيقات",
        description: "كل مؤسسة يجب أن يكون لديها عنوان على شبكة الإنترنت يبرز نشاطاتها ويتحدث عن أقسامها بكل تفصيل، ولدينا آليات مميزة لتنفيذ موقعك الإلكتروني و تطبيقك ومتجرك بأفضل وأحدث آلية",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servWeb",
        imgSrc: webImg,
        imgAlt: "Web Applications services",
        icon: webIcon,
        status: "active",
    },
    {
        id: 3,
        title: "سوشيال ميديا",
        description: "السوشيال ميديا هي لغة العصر في التسويق الإلكتروني وتأثيرها في الإنتشار والوصول إلى العملاء أصبحت شئ أساسي لكل منتج أو فكرة أونشاط، معنا ستجد خطة محددة للنشر والتسويق والوصول لعملاءك وجمهورك المحتمل",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servSocial",
        imgSrc: socialImg,
        imgAlt: "Social media services",
        icon: socialIcon,
        status: "active",
    },
    {
        id: 4,
        title: "الهوية البصرية",
        description: "الهوية جو عام يمثلك من خلال شعار ومجموعة لونية وأشكال متداخلة أو مبسطة تعبر عنك وتكون عنوان لك يعرفُكَ بها كل متلقٍ لها بتطبيقها على العناصر المختلفة",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servIdentity",
        imgSrc: identityImg,
        imgAlt: "Identity Services",
        icon: identityIcon,
        status: "active",
    },
    {
        id: 5,
        title: "باكج دعائي",
        description: "تطبيق لهويتك البصرية على أوراقك الرسمية (ورق مكاتبات - فواتير - إذن صرف - إذن استلام - ختم - كروت) حدد المطلوب ويشرفنا التنفيذ",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servAdv",
        imgSrc: advImg,
        imgAlt: "Advertise Services",
        icon: advIcon,
        status: "active",
    },
    {
        id: 6,
        title: "التغليف والعلب",
        description: "مما لا شك فيه أن التغليف الجيد والمبتكر لمنتجك ينقله إلى مستوى آخر، نحن معك لنساعدك لخروج منتجك بأفضل صورة ممكنة لدينا آليات كاملة لتصميم وطباعة وتنفيذ تغليف بأفكار جديدة رائعة",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servPackage",
        imgSrc: packageImg,
        imgAlt: "Packaging Services",
        icon: packageIcon,
        status: "active",
    },
    {
        id: 7,
        title: "لافتات دعائية",
        description: "يمكننا تنفيذ اللافتات الخارجية بكل أنواعها وأشكالها بما يتنساب مع نشاطك وبأفضل الوسائل والخامات التي تظهرك بشكل مميز ورائع",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servBanner",
        imgSrc: bannersImg,
        imgAlt: "Banners Services",
        icon: bannersIcon,
        status: "active",
    },
    {
        id: 8,
        title: "التصوير",
        description: "التصوير الجيد من اهم وسائل نجاح ظهور منتجك ومحتواك بشكل مميزلدينا فريق محترف من المصورين والمخرجين ومتخصصين الميديا لخروج محتواك بشكل مميز بداية من تجهيز الإسكربت ومروراً بالسينريو وصولا للتصوير بأحدث الكاميرات والأدوات الخاصة بالتصوير",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servPhoto",
        imgSrc: photoImg,
        imgAlt: "Photography Services",
        icon: photoIcon,
        status: "active",
    },
    {
        id: 9,
        title: "تنظيم معارض",
        description: "جناحك في المعرض يمثلك بشكل كامل حيث مشاركتك في المعارض يمثل احتكاك مباشر بالعملاء المحتملين وأقرانك في المجال، لذلك نحن في انتظارك لنبني لك تصور الجناح بداية من تصميم الجناح مودلينج 3D إلى تطبيقه وتنفيذه بكل دقة، من خلال تنفيذ مميز ورائع لما سيتم تصميمه",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servShows",
        imgSrc: showImg,
        imgAlt: "Shows Services",
        icon: showIcon,
        status: "active",
    },
    {
        id: 10,
        title: "البروفايل",
        description: "بروفايل الشركات: بوابة للإبداع والتميز، حيث تتجسد رؤيتنا وقيمنا. اكتشفوا تاريخنا ومنتجاتنا الابتكارية وشراكاتنا الملهمة لتحقيق النجاح المشترك",
        btnCaption: "طلب عرض سعر",
        btnLink: "/services/servProfile",
        imgSrc: profileImg,
        imgAlt: "Profile Services",
        icon: profileIcon,
        status: "active",
    },
    {
        id: 11,
        title: "مكتبة مسار",
        description: "تمثل مكتبة مسار منصة رائعة لخدمة القارئ بكل تنوعات طلباته، حيث توفر لكم كتب كثيرة في مجالات متعددة يمكن طلبها من خلال المنصة، لتصلكم في وقت قياسي وبأي كمية، ونعدكم أن نثري المنصة بكتب مميزة في كل المجالات وأن نكون على عهدنا بتوفيرها لكم بجودة رائعة أينما كنتم",
        btnCaption: "تصفح المكتبة",
        btnLink: "/masarLibrary",
        imgSrc: libImg,
        imgAlt: "Masar Library",
        icon: libIcon,
        status: "active",
    },
    {
        id: 12,
        title: "الوسائل التعليمية",
        description: "هنا لدينا العديد من الوسائل التعليمية والترفيهية للأطفال",
        btnCaption: "اذهب للوسائل",
        btnLink: "/masarLibrary?type=Children",
        imgSrc: libImg,
        imgAlt: "Wasael",
        icon: wasaelIcon,
        status: "",
    },
]


const ServiceCode = `${ServiceData.map(service => service.id)}`

export { ServiceData, ServiceCode };