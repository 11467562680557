import { useState } from "react";
// import Search from "../Search"
import Account from "./components/Account";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import NavPages from "./components/NavPages";
import "./navbar.css"

export default function NavItems() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (

        <div className="col navbar navbar-expand-md pb-3 pb-md-0 pt-md-0 justify-content-end">
            <button
                className="navbar-toggler border-0"
                type="button"
                onClick={toggleNav}
                aria-expanded={isNavOpen ? "true" : "false"}
            >
                {isNavOpen ?

                    <FontAwesomeIcon icon={faX} />
                    :
                    <FontAwesomeIcon icon={faBars} />
                }
            </button>
            {/* <HandleToggleNav /> */}
            <div className={`navbar-backdrop ${isNavOpen ? 'show' : ''}`} onClick={toggleNav}></div>
            <div className={`collapse navbar-collapse justify-content-between ${isNavOpen ? 'show' : ''}`} id="collapsibleNavbar">
                <NavPages isNavOpen={isNavOpen} />
                {/* <Search /> */}
                <Account />
            </div>
        </div>
    );
}

