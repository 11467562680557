// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiFetch } from '../../hooks/apiFetch';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { allBooksCategories } from "../../data/CategoriesData"
import { ToastContainer, toast } from 'react-toastify';
import SuccessErrorMsg from '../../utilities/SuccessErrorMsg';


export default function LibraryBook({ }) {
    // const [fav, setFav] = useState({ favBooks: [] });
    const [selectedBook, setSelectedBook] = useState("");
    const [libraryBooks, setLibraryBooks] = useState("");
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { bookSetId } = useParams()

    // console.log(bookSetId);


    // const selectedBook = libraryBooks?.data?.result.filter(book => book.id === bookSetId);

    // const selectedToFav = libraryBooks?.data?.result?.find(item => item.id === bookSetId); /////////

    // const addFavoriteHandle = () => {
    //     setFav(prevFav => ({
    //         favBooks: [selectedToFav, ...prevFav.favBooks]
    //     }));
    // };

    // const delFavoriteHandle = (bookId) => {
    //     setFav(prevFav => ({
    //         favBooks: prevFav.favBooks.filter(book => book.id !== bookId)
    //     }));
    // };
    const selectedBookCategory = allBooksCategories.find(cat => cat.en === selectedBook?.data?.category.type)

    // useEffect(() => {
    //     localStorage.setItem("userFavBooks", JSON.stringify(fav.favBooks));
    // }, [fav.favBooks]);

    useEffect(() => {
        async function fetchLibrary() {
            try {
                const book = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books/${bookSetId}`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const responseData = await book.json();


                setSelectedBook(responseData);
                // console.log(book);

            } catch (err) {
                setError("هناك مشكلة في جلب البيانات", err);
            }
        }

        fetchLibrary();
    }, []);

    moment.locale('en');


    async function onSubmit(data) {
        if (!Cookies.get('token')) {
            return (
                toast.warn(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> للشراء</p>)
            )
        }
        setLoading(true);
        // console.log("Form data: ", data, bookSetId); // Debug: Check form data

        try {
            const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/${bookSetId}`, {
                method: "POST",

                body: JSON.stringify(data)
            });

            // Redirect to another page after successful submission
            if (response.isSuccess) {
                // navigate('/');
                toast.success("طلبكم في السلة")
                setTimeout(() => {
                    window.location.reload();
                    // navigate('/masarLibrary');
                }, 3000);
                setSuccess(true)
            } else {
                console.log(response, response.message, response.status);
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            switch (error.response.status) {
                case 403:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link></p>));
                    break;
                case 500:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> أو تواصل مع الدعم</p>));
                    break;
                case 400:
                    setErrorMessage(toast.warn("تأكد من إدخال البيانات بالشكل الصحيح"));
                    break;
                default:
                    setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
            }

        } finally {
            setLoading(false);

            // navigate('/masarLibrary');

        }
    }



    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!selectedBook) {
        return <div className="loader"></div>;
    }
    return (
        <div className='container'>
            <Link type="button" className="btn-masar btn-dark-orange mx-2 mt-2 text-decoration-none"
                to={"/masarLibrary"}
            >المكتبة</Link>
            <div className='container col-12 col-md-10 mt-2'>
                <div className="p-4 bg-dark-blue rounded">
                    <div className="bookPage-box position-relative d-flex gap-3 flex-column flex-md-row">
                        <div className="bookPage-img position-relative">
                            <img
                                crossOrigin="anonymous" className="rounded img-fluid"
                                src={selectedBook?.data?.thumbnail ? selectedBook?.data?.thumbnail : "../images/BookSample-1.png"}
                                alt={`book-id-${selectedBook?.data?.id}`} />

                            {/* {!localStorage.getItem('favBooksData') || fav.favBooks[0].id !== selectedBook?.data?.id ?
                            <FontAwesomeIcon
                                onClick={addFavoriteHandle}
                                icon={faHeart}
                                className={`books-Fav-Icon position-absolute bottom-0 end-0 me-2 mb-2 fs-3 text-white`}
                            />
                            :
                            <FontAwesomeIcon
                                onClick={() => delFavoriteHandle(selectedBook?.data?.id)}
                                icon={faHeart}
                                className={`books-Fav-Icon position-absolute bottom-0 end-0 me-2 mb-2 fs-3 c-darkOrange `}
                            />} */}
                        </div>
                        <div className="d-flex flex-column justify-content-between text-white col-12 col-md-6">
                            <div>
                                <h5 className='mb-2'>{selectedBook?.data?.title}</h5>
                                <h6 className='bg-dark-orange rounded text-center p-1 col-4 my-4'>
                                    {selectedBook?.data?.price}
                                    <span> ريال</span>
                                </h6>
                            </div>
                            <table className="table table-sm book-data">
                                <tbody>
                                    <tr>
                                        <th scope="row">المؤلف</th>
                                        <td>{selectedBook?.data?.author}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">تاريخ النشر</th>
                                        <td>{selectedBook?.data?.publishedDate}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">التصنيف</th>
                                        <td>
                                            {selectedBookCategory.ar}
                                            {/* <Link to={"/masarLibrary"} className='text-white'
                                                onClick={() => {
                                                    navigate("/masarLibrary")
                                                }}>{selectedBookCategory.ar}</Link> */}

                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">عدد الصفحات</th>
                                        <td>{selectedBook?.data?.numberOfPages}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={`bookPage-status ${selectedBook?.data?.available ? "border-lightOrange" : "border-lightBlue"} position-absolute`}>
                            {selectedBook?.data?.available ? "متوفر" : "طباعة نسخة خاصة"}
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-md-row col-12 align-items-center align-items-md-start justify-content-md-between'>
                    <div className='col-12 col-md-8'>
                        <h6 className='py-4 c-darkOrange'>نبذة عن الكتاب</h6>
                        <p className='book-Desc'>{selectedBook?.data?.description}</p>
                    </div>
                    {!loading ?
                        <form className='d-flex flex-column text-center col-6 col-md-3 mt-4' onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="quantity">الكمية المطلوبة</label>
                            <input
                                id='quantity'
                                className='form-control w-75 mx-auto'
                                type="number"
                                min="1"
                                step="1"
                                {...register("quantity", {
                                    required: "الكمية مطلوبة",
                                    min: {
                                        value: 1,
                                        message: "أقل كمية ١"
                                    },
                                    valueAsNumber: true
                                })} />
                            {errors.quantity && <p className='errors-msg bg-warning p-1 rounded'>أقل كمية ١</p>}


                            <button className="float-end btn btn-masar btn-dark-orange mt-2" type="submit">إضافة للسلة</button>
                            <SuccessErrorMsg
                                ToastContainer={ToastContainer}
                            />
                            {/* {errorMessage &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>} */}
                            {/* {success &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                طلبكم في السلة
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>} */}
                        </form>

                        : <div className="loader"></div>
                    }
                </div>
                {/* <OurBooks /> */}
            </div>
        </div>
    );
}
