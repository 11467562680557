import { Link } from "react-router-dom";

export default function SliderDataBox(prop) {
    return (
        <>
            <div className="carousel--data p-3 text-white">
                <h5>{prop.title}</h5>
                <p className="d-none d-md-block">{prop.description}</p>
                <Link type="button" to={prop.btnLink} className="w-100 text-center text-decoration-none btn-masar btn-dark-orange d-none d-sm-block">{prop.btnCaption}</Link>
            </div>
            <img
                src={prop.imgSrc}
                className="w-100 d-block"
                alt={prop.imgAlt}
            />
        </>
    )
};