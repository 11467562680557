import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SuccessErrorMsg from "../../utilities/SuccessErrorMsg";
import { emailValidation } from '../../utilities/validations/inputsValidations';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


export default function ResetPassword() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    try {
      await axios.patch(
        `${process.env.REACT_APP_MASAR_API_URL}/auth/forgotpassword`, data,
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json'
          }
        }
      )
      toast.success("تم إرسال رسالة علي بريدكم")
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error("An error occurred during login", error);
      switch (error.response.status) {
        case 400:
          setErrorMessage(toast.warn("البريد الالكتروني غير مسجل فضلا تأكد منه"));
          break;
        default:
          setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='dashboard bg-light-blue col-12 position-relative'>
      <div className='bg-white h-50'></div>
      {loading ?
        <div className="loader"></div>
        :
        <div className='dashboard-form container col-10 col-md-6 px-4 py-5 text-start h-50 bg-dark-orange rounded-4 border border-2 shadow border-white position-absolute top-50 start-50 translate-middle'>
          <h4 className='text-white'>إعادة تعيين كلمة المرور</h4>
          <p className='text-white'>فضلا أدخل البريد الالكتروني المسجل</p>
          <form className='d-flex flex-column mx-auto my-5 gap-3 text-start' onSubmit={handleSubmit(onSubmit)}>
            <input className={`form-control ${errors.email && "border border-danger"}`} type="email" placeholder="البريد الالكتروني" {...register("email", { required: true, pattern: emailValidation })} />
            {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني صحيح</p>}


            <input className='btn btn-light-blue text-white' type="submit" value="إرسال" />
          </form>
          <SuccessErrorMsg
            ToastContainer={ToastContainer}
          />

        </div>
      }

      <div className='d-none d-sm-block col-6 bg-light-blue'>
      </div>
    </div>

  )
}
