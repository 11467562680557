import AboutData from "../data/AboutPageData"
import AboutLogo from "../assets/logos/masar-logo-about.jpg"
import AboutHeader from "../assets/images/AboutUs-Img.jpg"
import AboutSection from "../features/aboutUs/AboutSection";

export default function aboutUs() {
    return (
        <div className="aboutPage">
            <img src={AboutHeader} alt="about header" className="py-4 w-100" />
            <div className="container d-flex justify-content-between align-items-start flex-wrap text-center text-md-start">
                <h3 className="col-12 col-md-2 mt-2 c-darkOrange text-center-md" >من نحن؟</h3>

                <p className="col-12 col-md mt-3 mt-md-5 px-5 ps-md-0 lh-lg">{AboutData.about}</p>

                <img src={AboutLogo} alt="masar Logo" className="col-3 m-auto d-none d-md-block w-auto" />
            </div>
            <div className="bg-light-blue-10 mx-5 py-3 rounded">
                <AboutSection
                    headName="رُؤيتنا"
                    sectionName={AboutData.vision}
                />
            </div>

            <div className="mx-5 py-3 rounded">
                <AboutSection
                    headName="أهدافنا"
                    sectionName={AboutData.goal}
                />
            </div>
        </div>
    );
};
