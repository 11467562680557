import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../../../hooks/apiFetch';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../utilities/Pagination";

export function SettingUsers() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    // const [selectedOrder, setSelectedOrder] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const [usersData, setUsersData] = useState(null);
    // const [allUsersData, setAllUsersData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [userDataTotalCount, setUserDataTotalCount] = useState("");

    const totalPages = usersData?.data?.paginationInfo.totalPagesCount;
    // console.log(userDataTotalCount);


    async function handleUserDelete(id) {
        try {
            await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/${id}`, { method: "DELETE" });

            // Refetch the users list after deletion
            fetchUsersList();
        } catch (err) {
            setError("هناك مشكلة في الحذف");
        }
    }

    async function fetchUsersList() {
        try {
            const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
            const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users?${searchQuery ? "" : "page=" + currentPage + "&"}limit=${searchQuery ? userDataTotalCount : 10}`);

            setProfile(profileData);
            setUsersData(data);
            setUserDataTotalCount(data.data?.paginationInfo.totalCount)


        } catch (err) {
            setError("هناك مشكلة تواصل مع الدعم");
        }
    }

    useEffect(() => {
        fetchUsersList();
    }, [currentPage, userDataTotalCount, searchQuery]);

    ////////////////////

    // const handleOrderClick = (id) => {
    //     setSelectedOrder(id);
    // };

    moment.locale('en');
    // const date = moment(OrdersData[0].result[0].createdAt).format('Do MM YYYY');

    // console.log(النتائج);

    const AdminUsers = ({ id, fullName, email, mobilePhone, birthDate, role, createdAt }) => (

        <tr
        // className={`${orderStatus === "Pending"
        //     && "table-warning"} ${orderStatus === "Completed"
        //     && "table-success"}`}
        >

            <td className="col" > {`${moment(createdAt).format('YY')}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 2 }).format(
                id,)}`}</td>
            <td className="col">{fullName}</td>
            <td className="col">
                {moment(birthDate).format('DD/MM/YYYY')}</td>
            <td className="col">{mobilePhone}</td>
            <td className="col">{email}</td>
            <td className={`col ${profile.data?.role !== "superAdmin" && "d-none"}`} >

                <button className="btn-masar-sm btn-dark-orange"
                    onClick={() => handleUserDelete(id)} hidden={profile.data?.role !== "superAdmin"}
                ><FontAwesomeIcon className="pt-1" icon={faBan} /></button></td>

        </tr>

    );


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = usersData?.data?.result.filter(user =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.mobilePhone.includes(searchQuery)
    );

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!usersData) {
        return <div className="loader"></div>;
    }
    return (

        <div className='container position-relative d-flex col-11 col-md-10 m-0'>
            <div className='container mt-4'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    <div className="d-flex flex-wrap justify-content-center ">
                        <input
                            name="query"
                            className="form-control-sm border-0 w-100"
                            type="search"
                            placeholder="بحث"
                            value={searchQuery}
                            onChange={handleSearchChange} />

                        <div className="container mt-3 position-relative p-3 overflow-auto text-nowrap">

                            <table className="table table-hover">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">كود</th>
                                        <th scope="col">الاسم</th>
                                        <th scope="col">تاريخ الميلاد</th>
                                        <th scope="col">رقم الجوال</th>
                                        <th scope="col">البريد الالكتروني</th>
                                        <th scope="col" className={`${profile.data?.role !== "superAdmin" && "d-none"}`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user) => (
                                        <AdminUsers
                                            key={user.id}
                                            id={user.id}
                                            birthDate={user.birthDate}
                                            fullName={user.firstName + " " + user.lastName}
                                            email={user.email}
                                            mobilePhone={user.mobilePhone}
                                            role={user.role}
                                            createdAt={user.createdAt}
                                        />

                                    ))}
                                </tbody>
                            </table>

                        </div>
                        {totalPages > 1 &&
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
