import NavItems from "./NavItems"
import HeaderLogo from "./components/HeaderLogo";
import "./Header.css"

export default function Header() {
  return (
    <header className="py-0 bg-light-gray">
      <div className="container-md d-flex">
        <HeaderLogo />
        <NavItems />
      </div>
    </header>

  );
}
