import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utilities/ScrollToTop";
import Layout from "./layouts/layout";
import UserLayout from "./layouts/userLayout";
import ServicesLayout from "./layouts/servicesLayout";

import Home from "./pages/home";
import Services from "./pages/services";
import AboutUs from "./pages/aboutUs";
import Offers from "./pages/offers";
import Profile from "./pages/profile";
import Library from "./pages/masarLibrary";
import AdvancedServices from "./pages/advancedServices";

// Services Pages
// import ServBooks from "./pages/servBooks";
// import ServWeb from "./pages/servWeb";
// import ServSocial from "./pages/servSocial";
// import ServIdentity from "./pages/servIdentity";
// import ServAdv from "./pages/servAdv";
// import ServPackage from "./pages/servPackage";
// import ServBanner from "./pages/servBanner";
// import ServPhoto from "./pages/servPhoto";
// import ServShows from "./pages/servShows";
// import ServProfile from "./pages/servProfile";


import LogIn from "./features/loginAndRegister/logIn";
import Register from "./pages/register";
import OTPPage from "./features/user/login/OTP";
import MyAccount from "./pages/userAccount";
import AdminLayout from "./layouts/AdminLayout";
// import AdminLogIn from "./pages/adminLogIn";
// import AdminAccount from "./pages/adminDash";
import AdminNotifi from "./features/notification/adminNotifi";
import AdminModirators from "./features/modirators/adminModirators";
import AdminSetting from "./features/admin/adminSetting";
import { SettingBooks } from "./features/admin/setting/settingBooks";
import { SettingServices } from "./features/admin/setting/settingServices";
import { SettingPayments } from "./features/admin/setting/settingPayments";
import { SettingUsers } from "./features/admin/setting/settingUsers";
import { SettingProfile } from "./features/admin/setting/settingProfile";
import MyRequests from "./features/user/userRequests";
import MyPayments from "./features/user/userPayments";
import { Terms, Policy } from "./pages/terms";
import MyCart from "./features/user/userCart";
import { ServicesProviders } from "./features/serviceProvider/servicesProviders";
import ContactUs from "./pages/contactUs";
import NotFound from "./components/notFound";
import { PaymentForm } from "./features/payment/CheckoutPaymob";
import ActiveUserPassword from "./features/user/login/registerActiveUser";
import ResetPassword from "./features/loginAndRegister/resetPassword";
import LibraryBook from "./features/library/libraryBook";

// lazyload pages
const ServBooks = lazy(() => import("./features/services/servBooks"));
const ServWeb = lazy(() => import("./features/services/servWeb"));
const ServSocial = lazy(() => import("./features/services/servSocial"));
const ServIdentity = lazy(() => import("./features/services/servIdentity"));
const ServAdv = lazy(() => import("./features/services/servAdv"));
const ServPackage = lazy(() => import("./features/services/servPackage"));
const ServBanner = lazy(() => import("./features/services/servBanner"));
const ServPhoto = lazy(() => import("./features/services/servPhoto"));
const ServShows = lazy(() => import("./features/services/servShows"));
const ServProfile = lazy(() => import("./features/services/servProfile"));
const AdminAccount = lazy(() => import("./pages/adminDash"));
const AdminLogIn = lazy(() => import("./features/admin/Login/adminLogIn"));




function App() {
  return (
    <BrowserRouter>
      {/* // basename={process.env.PUBLIC_URL} */}
      <ScrollToTop />
      <Suspense fallback={<div className="loader"></div>}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="services" element={<ServicesLayout />}>
              <Route index element={<Services />} />
              <Route path="servBooks" element={<ServBooks />} />
              <Route path="servWeb" element={<ServWeb />} />
              <Route path="servSocial" element={<ServSocial />} />
              <Route path="servIdentity" element={<ServIdentity />} />
              <Route path="servPackage" element={<ServPackage />} />
              <Route path="servAdv" element={<ServAdv />} />
              <Route path="servBanner" element={<ServBanner />} />
              <Route path="servPhoto" element={<ServPhoto />} />
              <Route path="servShows" element={<ServShows />} />
              <Route path="servProfile" element={<ServProfile />} />
            </Route>
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="offers" element={<Offers />} />
            <Route path="advancedServices" element={<AdvancedServices />} />
            <Route path="profile" element={<Profile />} />
            <Route path="masarLibrary" element={<Library />} />
            <Route path="masarLibrary/:bookSetId" element={<LibraryBook />} />

            <Route path="terms" element={<Terms />} />
            <Route path="policy" element={<Policy />} />
            <Route path="servicesProviders" element={<ServicesProviders />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="payment" element={<PaymentForm />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<UserLayout />}>
            <Route path="/auth/reset-password/:token" element={<ActiveUserPassword />} />
            <Route path="logIn" element={<LogIn />} />
            <Route path="register" element={<Register />} />
            <Route path="OTP" element={<OTPPage />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="myAccount" element={<MyAccount />} />
            <Route path="myRequests" element={<MyRequests />} />
            <Route path="myBasket" element={<MyCart />} />
            {/* <Route path="myFav" element={<MyFavorits />} /> */}
            <Route path="myPayments" element={<MyPayments />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="adminLogin" element={<AdminLogIn />} />
            <Route path="admin" element={<AdminAccount />} />
            <Route path="adminNotifi" element={<AdminNotifi />} />
            <Route path="modirators" element={<AdminModirators />} />
            <Route path="setting" element={<AdminSetting />} >
              <Route path="books" element={<SettingBooks />} />
              <Route index element={<SettingBooks />} />
              <Route path="services" element={<SettingServices />} />
              <Route path="payments" element={<SettingPayments />} />
              <Route path="profile" element={<SettingProfile />} />
              <Route path="users" element={<SettingUsers />} />
            </Route>

          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
