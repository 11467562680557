// import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../../../hooks/apiFetch';
// import moment from 'moment';
import 'moment/locale/ar'
import Popup from 'reactjs-popup';
import { BookDataModify } from "../../../data/ModiratorData";
import { allBooksCategories } from "../../../data/CategoriesData"
import { Link } from "react-router-dom";
import Pagination from "../../../utilities/Pagination";
import { AddBooks } from "../../library/AddBooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from "@fortawesome/free-solid-svg-icons";


export function SettingBooks() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [selectedBook, setSelectedBook] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [settingBooks, setSettingBooks] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredBooksData = settingBooks?.data?.result.filter(item =>
        item.title.includes(searchQuery) ||
        item.author.includes(searchQuery) ||
        item.publishedDate.toString().includes(searchQuery)
    );


    const totalPages = settingBooks?.data?.paginationInfo.totalPagesCount;

    useEffect(() => {
        async function fetchSettingBooks() {
            try {
                const booksData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/library/books?${searchQuery ? "" : `${selectedCategory ? `type=${selectedCategory}&` : ""}page=${currentPage}&limit=12`}`);

                const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);

                setSettingBooks(booksData);
                setProfile(profileData);



            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchSettingBooks();
    }, [currentPage, selectedCategory]);


    const BookSample = ({ id, title, img, imgAlt, addDate, price, description, qty, available, author, pubYear, bookCategory, pages }) => (
        <div className="book-container col-6 col-lg-3 my-1 px-1" >

            {!selectedBook &&
                <Popup
                    trigger={
                        <Link className="text-decoration-none text-black">
                            {qty < 1 ? <FontAwesomeIcon icon={faBan} className="fs-1 position-absolute top-50 start-50 translate-middle text-warning text-opacity-50 bg-white w-75 h-100 bg-opacity-50 rounded" /> : ""}
                            <img
                                crossOrigin="anonymous"
                                src={img ? img : "../images/BookSample-1.png"}
                                alt={imgAlt}
                                className="rounded-2 w-100" />
                            <div className="book-title p-1 rounded mb-2">
                                <p>{title}</p>
                            </div>
                        </Link>
                    }
                    position="bottom left" modal>
                    <BookDataModify
                        title={title}
                        id={id}
                        img={img}
                        imgAlt={imgAlt}
                        addDate={addDate}
                        price={price}
                        description={description}
                        qty={qty}
                        available={available}
                        author={author}
                        pubYear={pubYear}
                        bookCategory={bookCategory}
                        pages={pages} />
                </Popup>
            }
        </div>

    );


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!settingBooks) {
        return <div className="loader"></div>;
    }

    return (

        <div className='container position-relative d-flex overflow-auto'>
            <div className='container mt-4 col-12'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3 pb-5">
                    <div className='d-flex flex-wrap justify-content-between'>

                        <select className="form-select form-select-sm w-auto mb-2" aria-label="books-category"
                            onChange={e => setSelectedCategory(e.target.value)}>
                            <option value="">الكل</option>
                            {allBooksCategories.map((category, index) =>
                                <option key={index} value={category.en}>{category.ar}</option>
                            )}
                        </select>
                        <input
                            name="queryBooks"
                            className="form-control-sm border my-2 col-9 col-md-4"
                            type="search"
                            placeholder={
                                selectedCategory
                                    ? `البحث في تصنيف ${allBooksCategories.find(category => category.en === selectedCategory)?.ar || ""
                                    }`
                                    : `البحث باسم المؤلف / الكتاب / سنة النشر`
                            }
                            value={searchQuery}
                            onChange={handleSearchChange} />

                        <div className="btn-masar d-flex justify-content-center align-items-center rounded-2 bg-white m-2 mt-0 fs-3 p-0 c-darkGray col-2"
                            data-bs-toggle="modal"
                            data-bs-target="#add-book">
                            +
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                        {(searchQuery ?
                            (filteredBooksData)
                            : (settingBooks?.data?.result)).map((data) => (
                                <BookSample
                                    key={data.id}
                                    id={data.id}
                                    title={data.title}
                                    img={data.thumbnail}
                                    imgAlt={`book_${data.id}`}
                                    addDate={data.createdAt}
                                    price={data.price}
                                    description={data.description}
                                    qty={data.quantity}
                                    available={data.available}
                                    author={data.author}
                                    pubYear={data.publishedDate}
                                    bookCategory={allBooksCategories.map(catg =>
                                        catg.en === data.category.type ? catg.ar : "")}
                                    pages={data.numberOfPages} />
                            ))}
                        {/* <div className="add-modirator d-flex justify-content-center align-items-center rounded-3 bg-white m-2 fs-1 c-darkGray"
                            data-bs-toggle="modal"
                            data-bs-target="#add-book">
                            +
                        </div> */}
                    </div>
                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }

                </div>
            </div>
            <AddBooks />
        </div>
    )
}
