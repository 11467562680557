import UserSideBar from "../../layouts/UserSideBar";
import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../hooks/apiFetch';
import { CartBooksSummary, CartOrdersSummary } from "../cart/CartSummery";
import { CartBooksItems, CartOrdersItmes } from "../cart/CartItems"
import Pagination from "../../utilities/Pagination";
import { allServicesCategories } from "../../data/CategoriesData"
import { useForm } from 'react-hook-form';

export default function MyCart({ paymentType }) {
    const [cartBooks, setCartBooks] = useState(null);
    const [cartOrders, setCartOrders] = useState(null);
    const [cartStats, setCartStats] = useState(null);
    const [error, setError] = useState(null);
    const [profile, setProfile] = useState(null);
    const [currentPageBooks, setCurrentPageBooks] = useState(1);
    const [currentPageOrders, setCurrentPageOrders] = useState(1);
    const [loading, setLoading] = useState(false);
    // const [dataUpdate, setDataUpdate] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');
    const { setValue } = useForm();

    const totalPagesBooks = cartBooks?.data?.paginationInfo.totalPagesCount;
    const totalPagesOrders = cartOrders?.data?.paginationInfo.totalPagesCount;

    const allCartDataBooks = cartBooks?.data?.result;
    const allCartDataOrders = cartOrders?.data?.result;


    useEffect(() => {
        async function fetchCartItems() {
            try {
                const booksData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/books?page=${currentPageBooks}&limit=3`);
                const ordersData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/orders?page=${currentPageOrders}&limit=4`);
                const cartStatsData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/statistics`);
                const profileData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);

                setCartBooks(booksData);
                setCartOrders(ordersData);
                setCartStats(cartStatsData);
                setProfile(profileData);

            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchCartItems();
    }, [currentPageOrders, currentPageBooks]);


    async function cartOrderDelete(data, orderId) {
        setLoading(true);
        console.log("Form data: ", data, orderId); // Debug: Check form data
        // setValue("status", e.target.value)

        try {
            const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/${orderId}`, {
                method: "PATCH",
                body: JSON.stringify(data)
            });

            // Redirect to another page after successful submission
            if (response.isSuccess) {
                // navigate('/');
                // setTimeout(() => {
                //     window.location.reload();
                //     // navigate('/masarLibrary');
                // }, 3000);
                // setDataUpdate(true)
            } else {
                console.log(response, response.message, response.status);
                setError("حدث خطأ ما، حاول مرة أخرى", response.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            setError("حدث خطأ ما، حاول مرة أخرى");
        } finally {
            setLoading(false);

            // navigate('/masarLibrary');

        }
    }


    async function cartBookDelete(id) {
        setLoading(true)
        try {
            await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/book/${id}`, { method: "DELETE" });
            const updatedCartData = {
                ...cartBooks,
                data: {
                    ...cartBooks.data,
                    result: allCartDataBooks.filter((item) => item.id !== id)
                }
            };
            setCartBooks(updatedCartData);

            // Update cart stats based on the new cart data
            const updatedCartStats = {
                ...cartStats,
                data: {
                    totalBooks: updatedCartData.data.result.reduce((total, item) => total + item.quantity, 0),
                    totalPrice: updatedCartData.data.result.reduce((total, item) => total + (item.book.price * item.quantity), 0)
                }
            };
            setCartStats(updatedCartStats);

        } catch (err) {
            setError("هناك مشكلة في الحذف");
        } finally {
            setLoading(false)

        }
    }

    // const shownService = () => allServicesCategories.find(cat => cat.en === cartOrders?.data?.result[0].service.name)
    const userName = profile?.data?.firstName;


    // Calculate the total price
    // const totalPrice = cartBooks?.data?.result.reduce((total, item) => {
    //     return total + (item.book.price * item.quantity);
    // }, 0);
    // const totalBooks = cartBooks?.data?.result.reduce((total, item) => {
    //     return total + (item.quantity);
    // }, 0);


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!cartBooks) {
        return <div className="loader"></div>;
    }

    return (
        <div className='container dashboard col-12 position-relative d-flex'>
            <div className='container col-1 col-md-2 m-0 text-start'>
                <UserSideBar />
            </div>
            <div className='container mt-4 col-11 col-md-10'>
                <h6 className="text-center text-md-end m-0 c-darkOrange"><span className="text-black">أهلا/ </span>
                    {userName}
                </h6>
                <div className="container mt-3 py-1 rounded bg-dark-blue">
                    <div className="d-flex flex-column flex-md-row">
                        {loading ?
                            <div className="loader-orange col-12 col-md-9"></div>
                            :
                            <div className="d-flex flex-wrap justify-content-around">
                                {allCartDataBooks.map((item) => (
                                    <CartBooksItems key={item.id}
                                        bookTitle={item.book.title}
                                        bookQty={item.quantity}
                                        bookImg={item.book?.thumbnail}
                                        bookImgAlt={item.book.createdAt}
                                        bookPrice={item.book.price}
                                        handleOnClick={() => cartBookDelete(item.id)}
                                    />
                                ))}

                            </div>
                        }
                        <CartBooksSummary
                            totalBooks={cartStats?.data?.totalBooks}
                            totalBooksPrice={cartStats?.data?.totalBooksPrice} />
                    </div>
                    {totalPagesBooks > 1 &&
                        <Pagination
                            currentPage={currentPageBooks}
                            setCurrentPage={setCurrentPageBooks}
                            totalPages={totalPagesBooks} />
                    }
                    <div className="d-flex flex-column flex-md-row bg-white rounded mb-3">
                        {loading ?
                            <div className="loader-orange col-12 col-md-9"></div>
                            :
                            <div className="d-flex flex-wrap flex-column col-12 col-md-9">
                                {allCartDataOrders.map((item) => (
                                    <CartOrdersItmes
                                        key={item.id}
                                        orderService={
                                            allServicesCategories.map(cat =>
                                                cat.en === item.service?.name ? cat.ar : "")}
                                        orderPrice={item.offerDetails?.price}
                                        handleOnClick={(e) => {
                                            setValue("status", e.target.value);
                                            cartOrderDelete({ "status": "Rejected" }, item.id);
                                        }}
                                    />
                                ))}
                                {totalPagesOrders > 1 &&
                                    <Pagination
                                        currentPage={currentPageOrders}
                                        setCurrentPage={setCurrentPageOrders}
                                        totalPages={totalPagesOrders} />
                                }
                            </div>}
                        <CartOrdersSummary
                            setValue={setValue}
                            totalOrders={cartStats?.data?.totalApprovedOrders}
                            totalOrdersPrice={cartStats?.data?.totalOrdersPrice} />
                    </div>
                </div>
            </div>
        </div>
    )
};

// to Add 
// Ordar Approved or Rejected from user