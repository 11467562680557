import ListData from "./component/ListData"
import SliderButtons from "./component/SliderButtons";
import MainSlideUnit from "./component/MainSlideUnit";

export default function HomeSlider() {
    return (
        <div id="carouselId" className="carousel slide mt-4 container-md " data-bs-ride="carousel">
            <ListData dataTarget="#carouselId" />
            <MainSlideUnit />
            <SliderButtons
                btnDirection="prev"
                btnId="#carouselId"
            />
            <SliderButtons
                btnDirection="next"
                btnId="#carouselId"
            />
        </div>

    )
};