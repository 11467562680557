import HomeSlider from "../features/homeSlider/HomeSlider"
import AllServicesBox from "../features/services/AllServicesBox"
import LatestBooks from "../features/latestBooks/LatestBooks"
// import { useState, useEffect } from "react";
// import Cookies from 'js-cookie';


function Home() {
    // const [login, setLogin] = useState(false);


    // useEffect(() => {
    //     if (Cookies.get('token')) {
    //         setLogin(true)
    //     } else {
    //         setLogin(false)

    //     }
    // }, []);


    return (
        <>
            <HomeSlider />
            <AllServicesBox />
            <LatestBooks />
        </>
    );
};


export default Home;
