import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ToastContainer, ToastContainerProps, toast } from 'react-toastify';
import { apiFetchAdmin } from '../hooks/apiFetch';
// import useSound from 'use-sound';
// import playSound from '../../public/sounds/alert.mp3';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
    measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
};
// const app = initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

const MsgGetToken = () => {
    const [token, setToken] = useState(null);
    const [notifiMessage, setNotifiMessage] = useState(null);
    const [notifiTitle, setNotifiTitle] = useState(null);
    // const [playOn] = useSound("/sounds/alert.mp3");

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        //Send token to server
        const sendTokenToServer = async (token) => {
            try {
                const response = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/notifications/register-fcm-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fcmToken: token }),
                });
                // const data = await response.json();
                // console.log('Server response:', response.data);
                // setNotifiMessage(response.data.message);
                // toast.info(`Test Notifi: ${notifiMessage}`);

            } catch (error) {
                console.error('Error sending token to server:', error);
            }
        };

        Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_VAPID_KEY })
                        .then((newToken) => {
                            setToken(newToken);
                            // console.log('Token:', newToken);
                            sendTokenToServer(newToken);
                        })
                        .catch((error) => {
                            console.error('Error getting token:', error);
                        });
                } else {
                    console.error('Permission denied');
                }
            })
            .catch((error) => {
                console.error('Error requesting permission:', error);
            });


        // Listen for incoming messages
        onMessage(messaging, (payload) => {
            // console.log('Received message:', payload);
            // setNotifiMessage(payload.notification.body);
            // setNotifiTitle(payload.notification.title);
            const { title, body } = payload.notification;
            // if (Notification.permission === 'granted') {
            //     console.log(Notification.permission);
            //     toast.info(`${notifiTitle}: ${notifiMessage}`);
            //     new Notification(notifiTitle, {
            //         body: notifiMessage,
            //     }
            //     );
            // }

            if (Notification.permission === 'granted') {
                new Notification(title, {
                    body: body,
                });
            }
            // Display toast notification
            toast.info(`${title}: ${body}`);
            // playOn()


        });

        // return () => {
        //     // Clean up the messaging instance when the component unmounts
        //     messaging.onMessage(null); //this make error that .onMassage not a function
        // };
    }, []);

    return (
        <ToastContainer
            position="top-right"
            rtl={true}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
            TypeOptions="success"
        />
    );
};

export default MsgGetToken;
