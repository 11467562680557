import { allProfilesCategories } from "../data/CategoriesData"
import Pagination from "../utilities/Pagination";
import React, { useState, useEffect } from 'react';
import { apiFetch } from '../hooks/apiFetch';

export default function Profile() {
    const [Category, setCategory] = useState(null);
    const [profileWorks, setProfileWorks] = useState("");
    const [allProfileWorks, setAllProfileWorks] = useState("");
    const [profile, setProfile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);





    const filteredProfileWorksData = selectedCategory === null ? profileWorks?.data?.result : profileWorks?.data?.result.filter(item => item.service.name === selectedCategory);

    const totalPages = profileWorks?.data?.paginationInfo.totalPagesCount;

    const ProfileSample = ({ id, imgSrc, imgAlt, imgCat, description }) => (
        <div className="admin-profile-img col-12 col-md-4 p-1 position-relative">
            <p className="position-absolute bottom-0 start-50 mb-4 p-1 translate-middle-x bg-white w-75 text-center rounded">{imgCat}</p>
            <img crossOrigin="anonymous"
                src={imgSrc ? imgSrc : "/images/Profile-Sample0.jpg"}
                alt={imgAlt}
                className="rounded-4 w-100 border"
            />
        </div>
    );



    useEffect(() => {
        async function fetchProfileWorks() {
            try {
                const allWorks = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/works`);
                const works = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/works?${selectedCategory ? `service=${selectedCategory}&` : ""}page=${currentPage}&limit=12`);

                setAllProfileWorks(allWorks);
                setProfileWorks(works);

            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchProfileWorks();
    }, [currentPage, selectedCategory]);

    // const selectedProfileCategory = allProfilesCategories.find(cat => cat.en === allProfileWorks?.data?.result[0].service.name)

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!profileWorks) {
        return <div className="loader"></div>;
    }


    return (
        <div className="container mt-4">
            <h3 className="c-darkOrange col-12">من أعمالنا</h3>
            <div className="d-flex flex-wrap justify-content-between">
                <ul className="list-group col-3 col-md-2 pt-2 sticky-top h-100">
                    <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`} onClick={() => setSelectedCategory(null)}>
                        عرض الكل
                    </li>
                    {allProfilesCategories.map((category, index) => (
                        <li key={index} className={`list-group-item list-group-item-action ps-2 ${selectedCategory === category.en ? 'active' : ''}`} onClick={() => setSelectedCategory(category.en)}>
                            {category.ar}
                        </li>
                    ))}
                </ul>
                <div className="d-flex flex-wrap justify-content-evenly col-8 col-md-9 pt-1 align-content-start">
                    {filteredProfileWorksData.map((item, index) => (
                        <ProfileSample
                            key={item.id}
                            id={item.id}
                            description={item.description}
                            imgSrc={item.thumbnail}
                            imgAlt={`profileWorks_${item.id}`}
                            imgCat={allProfilesCategories.map(cat =>
                                cat.en === item.service?.name ? cat.ar : "")
                            } />
                    ))}
                </div>
            </div>
            <div className="col-12">
                {totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages} />
                }
            </div>
        </div>
    );
}






// export default function profile() {
//     return (
//         <div className="container mt-4">
//             <h3 className="c-darkOrange col-12">من أعمالنا</h3>
//             <div className="d-flex flex-wrap justify-content-between">
//                 <div className="list-group col-3 col-md-2 pt-2 sticky-top h-100">
//                     {categories.map((category, index) => (
//                         <a href="#"
//                             key={index} className="list-group-item list-group-item-action ps-2">
//                             {category}
//                         </a>
//                     ))}
//                 </div>
//                 <div className="col-8 col-md-9 pt-1">
//                     {profileData.map((item, index) => (
//                         <img
//                             key={index}
//                             src={item.imgSrc}
//                             alt={item.imgAlt}
//                             className="rounded-4 col-12 col-md-4 p-1" />
//                     ))}
//                 </div>
//             </div>
//         </div>
//     )
// }


// {
//     SideBar.map((items, index) => (
//         <div
//             key={index}
//             className="list-group col-3 col-md-2 pt-2 sticky-top h-100">
//             {items.lists.map((list, index) => (
//                 <a href="#" key={index} className="list-group-item list-group-item-action ps-2">
//                     {list}
//                 </a>
//             ))}
//         </div>
//     ))
// }
