export default function SliderButtons(prop) {
    return (
        <button
            className={`carousel-control-${prop.btnDirection}`}
            type="button"
            data-bs-target={prop.btnId}
            data-bs-slide={prop.btnDirection}
        >
            <span className={`carousel-control-${prop.btnDirection}-icon`} aria-hidden="true"></span>
            <span className="visually-hidden">{prop.btnDirection}</span>
        </button>
    )
};