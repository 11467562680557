import { ServiceData } from "../../../lib/useServiceData"

export default function ListData({ dataTarget }) {
    return (
        <ol className="carousel-indicators list-none">
            {ServiceData.map((service, index) => (
                <li key={index}
                    data-bs-target={dataTarget}
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : undefined}
                    aria-current={index === 0 && "true"}
                    aria-label={service.imgAlt}
                ></li>
            ))}
        </ol>
    )
};