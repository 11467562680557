import { ColumnOne, ColumnTwo, ColumnThree } from "./components/FooterLinks"
import CopyRight from "./components/CopyRight"
import FooterLogo from "./components/FooterLogo"
import "./Footer.css"

export default function Footer() {

  return (
    <footer className="bg-dark-blue mt-5 pt-4 pb-2 text-white">
      <div className="container d-flex align-items-center flex-wrap flex-sm-row flex-column-reverse justify-content-center gap-5">
        <ColumnOne />
        <ColumnTwo />
        <ColumnThree />
        <FooterLogo />
      </div>
      <div className="mt-4 pt-2 border-top border-1">
        <CopyRight />
      </div>
    </footer>
  );
}
