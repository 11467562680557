import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import FooterSmall from "../components/footer/FooterSmall";

export default function UserLayout() {
    return (
        <>
            <Header />
            <main style={{ minHeight: "calc(100vh - 145px)" }}>
                <Outlet />
            </main>
            <FooterSmall />
        </>
    );
}

