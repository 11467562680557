import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import OthersData from '../../../data/OthersLinksData.json';


export default function GeneralLinks() {

    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        } else {
            setIsLogIn(false)
        }
    }, [isLogIn]);

    return (
        <ul className='col-9 col-sm-5 col-md-4 col-lg-3'>
            {OthersData.map((data, index) => (
                <li key={index} className='footer-links'>
                    <Link to={data.link} className={`nav-link p-1 ${isLogIn && data.logInClass}`} aria-current="page" >
                        {data.title}</Link>
                </li>
            ))}
        </ul>
    )
};
