import { ServiceData } from "../../../lib/useServiceData"
import SliderDataBox from "./SliderDataBox"

export default function MainSlideUnit() {
    return (
        <div className="carousel-inner" role="listbox">
            {ServiceData.map((service, index) => (
                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item "}>
                    <SliderDataBox
                        title={service.title}
                        description={service.description}
                        btnLink={service.btnLink}
                        btnCaption={service.btnCaption}
                        imgSrc={service.imgSrc}
                        imgAlt={service.imgAlt}
                    />
                </div>
            ))}
        </div>
    )
};