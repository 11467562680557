import { Link } from "react-router-dom";

export default function BookContainer({ index, item, handleBookClick }) {
    return (
        <div key={index} className="book-container text-center ">
            <Link className="text-decoration-none text-black"
                onClick={() => handleBookClick(item.id)}>
                <img key={index}
                    crossOrigin="anonymous"
                    src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                    className="m-auto d-block rounded-2"
                    alt={`book-id-${item.id}`}
                />
                <div className="book-title p-1 rounded">
                    <p>{item.title}</p>
                </div>
            </Link>
        </div>
    )
}