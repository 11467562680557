export function SampleNextArrow(props) {

    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, borderRadius: "50%", backgroundColor: "#bbbdbf", textAlign: "center", paddingTop: "1px" }}
            onClick={onClick}
        />
    );
}




export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, borderRadius: "50%", backgroundColor: "#bbbdbf", textAlign: "center", paddingTop: "1px" }}
            onClick={onClick}
        />
    );
}
