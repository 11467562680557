import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { apiFetch } from '../../hooks/apiFetch';
import { masarPaymobInt, masarPaymobIntTest } from "../../hooks/apiAxios"
import SuccessErrorMsg from "../../utilities/SuccessErrorMsg"
import { allServicesCategories } from '../../data/CategoriesData';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from "react-toastify";


export const PaymentForm = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [cartBooks, setCartBooks] = useState(null);
    const [cartOrders, setCartOrders] = useState(null);
    const [cartStats, setCartStats] = useState(null);
    const [paymentToken, setPaymentToken] = useState(null);
    const [testMode, setTestMode] = useState(false);


    const paymentMethodsTest = [5745];
    const paymentMethods = [5846, 5847];
    const paymentType = sessionStorage.getItem('paymentType');
    const firstName = profile?.data?.firstName
    const lastName = profile?.data?.lastName;
    const userEmail = profile?.data?.email;
    const userId = profile?.data?.id;

    const payAmount = paymentType === "services" ? cartStats?.data?.totalOrdersPrice : cartStats?.data?.totalBooksPrice;
    // const payQty = paymentType === "services" ? cartStats?.data?.totalApprovedOrders : cartStats?.data?.totalBooks;

    const { register, handleSubmit, setValue, formState: { errors }
    } = useForm({
        defaultValues: {
            currency: 'SAR',
            redirection_URL: 'https://masar-t.com.sa'
        }
    });


    const checkOutPayMob = (CSK) =>
        !testMode ?
            window.open(`${process.env.REACT_APP_PAYMOB_BASE_URL}/unifiedcheckout/?publicKey=${process.env.REACT_APP_PAYMOB_PUBLIC_KEY}&clientSecret=${CSK}`, '_self') :
            window.open(`${process.env.REACT_APP_PAYMOB_BASE_URL}/unifiedcheckout/?publicKey=${process.env.REACT_APP_PAYMOB_PUBLIC_TESTKEY}&clientSecret=${CSK}`, '_self');


    // const checkOutPayMobTest = (CSK) =>
    //     window.open(`${process.env.REACT_APP_PAYMOB_BASE_URL}/unifiedcheckout/?publicKey=${process.env.REACT_APP_PAYMOB_PUBLIC_TESTKEY}&clientSecret=${CSK}`, '_blank');


    Cookies.set('paymentToken', paymentToken?.data, { expires: 7, secure: true, path: "/" });


    async function onSubmit(formData) {
        setLoading(true);
        console.log(formData);

        try {
            const response = await (testMode ? masarPaymobIntTest : masarPaymobInt)(formData);

            if (response.data.id) {
                // if (response.ok) {
                // setDataUpdate(true);
                toast.success("سيتم توجيهك لصفحة الدفع")
                setTimeout(() => {
                    // window.location.reload();
                    checkOutPayMob(response.data.client_secret)
                    sessionStorage.removeItem("paymentType");
                }, 3000);
                // console.log(response.data);

            } else {
                setErrorMessage(toast.error("حدث خطأ ما، حاول مرة أخرى"), response.data);
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
        } finally {
            setLoading(false);

        }
    }

    useEffect(() => {
        async function fetchUserData() {
            try {
                const payToken = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/payment/token`);
                const profileData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                const booksData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/books`);
                const ordersData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/orders`);
                const cartStatsData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/carts/my-cart/statistics`);

                setPaymentToken(payToken);
                setProfile(profileData);
                setCartBooks(booksData);
                setCartOrders(ordersData);
                setCartStats(cartStatsData);
            } catch (err) {
                setErrorMessage("هناك مشكلة في جلب البيانات");
            }
        }

        fetchUserData();
    }, []);



    return (
        <form className='container d-flex flex-wrap flex-column flex-md-row mt-5 ' onSubmit={handleSubmit(onSubmit)}>
            <div className='col-12 col-md-7'>
                <div>
                    <h5 className='c-darkBlue'>تفاصيل الدفع</h5>
                    {!payAmount ?
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"></span>
                        :
                        <input name="amount"
                            value={new Intl.NumberFormat("en-SA", { style: 'currency', currency: 'SAR' }).format(payAmount)}
                            {...register("amount", setValue("amount", payAmount * 100))}
                            disabled
                            className='bg-light-blue p-1 rounded text-white text-center col-12 col-md-10 border-0 mb-3'>

                        </input>}


                    <p className='c-darkOrange '>
                        أختر طريقة الدفع
                    </p>
                    <div className='d-flex flex-column flex-md-row justify-content-center flex-wrap gap-1 my-3  align-items-center bg-light-gray p-1 rounded  text-center col-12 col-md-10'>
                        {(testMode ? paymentMethodsTest : paymentMethods).map((item, index) =>
                            <div key={index} className=''>
                                <input type="radio" className={`btn-check`} name="payment_methods"
                                    id={item} autoComplete="off"
                                    required
                                    onChange={e => setValue("payment_methods", [item])}
                                />
                                <label className={`${errors.payment_methods && "border-2 border-danger"} btn btn-light`} htmlFor={item}>
                                    <img className='pay-options'
                                        src={`/images/${item}paySA.png`}
                                        alt={`${item}_pay`} /></label>
                                {errors.payment_methods && <p className='errors-msg'>اختر واحدة</p>}

                            </div>
                        )}
                    </div>

                </div>
                <div className='col-12 col-md-10'>
                    {/* <h6>معلومات الفوترة</h6> */}
                    {!profile ?
                        <span
                            className="spinner-border spinner-border-sm slef"
                            role="status"
                            aria-hidden="true"></span>
                        :
                        <>
                            <div className="input-group input-group-sm py-1">
                                <input
                                    className={`form-control ${errors.first_name && "border border-danger"}`}
                                    type="hidden"
                                    // type="text"
                                    name='first_name'
                                    defaultValue={firstName}
                                    required
                                    {...register("billing_data.first_name", { required: true })}
                                />
                                <input
                                    className={`form-control ${errors.first_name && "border border-danger"}`}
                                    type="hidden"
                                    {...register("customer.first_name")}
                                    value={firstName}
                                />

                                {errors.first_name && <p className='errors-msg'>الاسم مطلوب</p>}
                            </div>
                            <div className="input-group input-group-sm  py-1">
                                <input className={`form-control ${errors.last_name && "border border-danger"}`}
                                    type="hidden"
                                    // type="text"
                                    name='last_name'
                                    defaultValue={lastName}
                                    {...register("billing_data.last_name", { required: true })}
                                />
                                <input
                                    className={`form-control ${errors.first_name && "border border-danger"}`}
                                    type="hidden"
                                    {...register("customer.last_name")}
                                    value={lastName}
                                />
                                {errors.last_name && <p className='errors-msg'>الاسم مطلوب</p>}
                            </div>

                            <div className="input-group input-group-sm  py-1">
                                <input className={`form-control ${errors.phone_number && "border border-danger"}`}
                                    type="hidden"
                                    name='phone_number'
                                    defaultValue={profile?.data?.mobilePhone}
                                    required
                                    {...register("billing_data.phone_number", { required: true })}
                                />
                            </div>
                            <div className="input-group input-group-sm  py-1">
                                <input className={`form-control ${errors.email && "border border-danger"}`}
                                    type="hidden"
                                    defaultValue={profile?.data?.email}
                                    required
                                    name='email'
                                    {...register("billing_data.email", { required: true })}
                                />
                                <input className={`form-control ${errors.email && "border border-danger"}`}
                                    type="hidden"
                                    value={userId}
                                    required
                                    name='userId'
                                    {...register("billing_data.floor")}
                                />
                                <input className={`form-control ${errors.email && "border border-danger"}`}
                                    type="hidden"
                                    value={paymentType}
                                    required
                                    name='paymentType'
                                    {...register("billing_data.building")}
                                />
                                <input
                                    className={`form-control ${errors.first_name && "border border-danger"}`}
                                    type="hidden"
                                    {...register("customer.email")}
                                    value={userEmail}
                                />
                            </div>
                            <div className="input-group input-group-sm  py-1">
                                <span className="input-group-text">العنوان</span>
                                <input className={`form-control ${errors.street && "border border-danger"}`}
                                    type="text"
                                    name='street'
                                    {...register("billing_data.street", { required: true })} />
                                {errors.street && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                            </div>

                            <div className="input-group input-group-sm py-1">
                                <span className="input-group-text">المدينة</span>
                                <input className={`form-control ${errors.city && "border border-danger"}`}
                                    type="text"
                                    name='city'
                                    {...register("billing_data.city", { required: true })} />
                                {errors.city && <p className='errors-msg'>مطلوب</p>}
                            </div>
                            <div className="d-none input-group input-group-sm  py-1">
                                <span className="input-group-text">الدولة</span>
                                <input className={`form-control`}
                                    type="text"
                                    defaultValue="Saudi Arabia"
                                    {...register("billing_data.country")} />
                            </div>
                        </>}
                </div>

            </div>
            <div className='col-12 col-md-5 bg-light-gray py-3 pe-3 ps-2 mt-3 mt-md-0 rounded'>
                <h6 className=''>المنتجات / الخدمات</h6>
                {(paymentType === "services" & !cartOrders) || (paymentType === "books" & !cartBooks) ?
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    :
                    <>
                        {paymentType === "services" ?

                            (cartOrders?.data?.result.map((item, index) => (
                                <div key={index} className="d-flex text-white bg-dark-gray mt-2 ms-2 p-1 col-12 rounded align-items-center px-2">

                                    {/* Service Name */}
                                    <span className="col-7">
                                        <input
                                            type="hidden"
                                            {...register(`items[${index}].name`)}
                                            value={item.service?.name}
                                        />
                                        {allServicesCategories.map(cat =>
                                            cat.en === item.service?.name ? cat.ar : "")}
                                    </span>

                                    {/* Offer Price */}
                                    <span className="col text-end border-0 bg-transparent text-white">
                                        <input
                                            type="hidden"
                                            {...register(`items[${index}].amount`, { valueAsNumber: true })}
                                            value={item.offerDetails?.price * 100}
                                            readOnly
                                        />
                                        {new Intl.NumberFormat("en-SA", { style: 'currency', currency: 'SAR' }).format(item.offerDetails?.price)}
                                    </span>

                                    {/* Quantity */}
                                    <span className={`col-1 text-end ${!item.quantity && "d-none"}`}>
                                        <input
                                            type="hidden"
                                            {...register(`items[${index}].quantity`, { valueAsNumber: true })}
                                            value={item.quantity ? item.quantity : 1}
                                        />
                                        {item.quantity}
                                    </span>

                                </div>
                            )))


                            : (
                                cartBooks?.data?.result.map((item, index) => (
                                    <div key={index} className="d-flex text-white bg-dark-gray mt-2 ms-2 p-1 col-12 rounded align-items-center px-2">

                                        {/* Books Names */}
                                        <span className="col-7">
                                            <input
                                                type="hidden"
                                                {...register(`items[${index}].name`)}
                                                value={item.book?.title}
                                            />

                                            {item.book?.title}
                                        </span>

                                        {/* Offer Price */}
                                        <span className="col text-end border-0 bg-transparent text-white">
                                            <input
                                                type="hidden"
                                                {...register(`items[${index}].amount`, { valueAsNumber: true })}
                                                value={item.book?.price * 100}
                                                readOnly
                                            />
                                            {new Intl.NumberFormat("en-SA", { style: 'currency', currency: 'SAR' }).format(item.book?.price)}
                                        </span>

                                        {/* Quantity */}
                                        <span className={`col-1 text-end ${!item.quantity && "d-none"}`}>
                                            <input
                                                type="hidden"
                                                {...register(`items[${index}].quantity`, { valueAsNumber: true })}
                                                value={item.quantity ? item.quantity : 1}
                                            />
                                            {item.quantity}
                                        </span>
                                    </div>
                                )))

                        }
                    </>}
            </div>

            <br />
            <button className='btn-masar btn-dark-orange col-12 col-md-10 mx-auto my-3' type="submit">
                {loading &&
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                }
                انتقل لصفحة الدفع
            </button>
            <SuccessErrorMsg
                ToastContainer={ToastContainer}

            // errorMessage={errorMessage}
            // dataUpdate={dataUpdate}
            // successMessage={"سيتم توجيهك لصفحة الدفع"}

            />
        </form>
    );
};

export default PaymentForm;
