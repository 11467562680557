import { Link } from "react-router-dom";
import { ServiceData } from "../../../lib/useServiceData"
import "../navbar.css"

export default function ServiceDropdownList() {

    return (

        <ul id="servDropdown"
            className="flex-wrap position-absolute bg-light-gray border border-white rounded p-2 col-12 list-unstyled shadow">
            {ServiceData.map((data, index) => (
                <li key={index} className="col-12 col-md-4" >
                    <Link
                        to={data.btnLink}
                        className={`dropdown-item px-1 py-2 ${data.status}`}>
                        {data.title}
                    </Link>
                </li>
            ))}
        </ul>
    );
}

