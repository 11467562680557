import CopyRight from "./components/CopyRight"
import "./Footer.css"

export default function FooterSmall() {
  return (
    <footer className="bg-dark-blue pt-1 pb-2 text-white">
      <CopyRight />
    </footer>
  );
}
