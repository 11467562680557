import { Outlet } from "react-router-dom";
import AdminHeader from "../components/header/AdminHeader";
import FooterSmall from "../components/footer/FooterSmall";
import MsgGetToken from '../utilities/MsgGetToken';
import 'react-toastify/dist/ReactToastify.css';


export default function AdminLayout() {

    return (
        <>
            <AdminHeader />
            <main style={{ minHeight: "calc(100vh - 145px)" }}>
                <Outlet />
                <MsgGetToken />
            </main>
            <FooterSmall />
        </>
    );
}

