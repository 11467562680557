import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import SuccessErrorMsg from "../utilities/SuccessErrorMsg";
import { ReCaptchaHidden } from '../utilities/ReCaptchaForForms';
import { emailValidation, mobileValidation } from '../utilities/validations/inputsValidations';
import { toast, ToastContainer } from 'react-toastify';


export default function Register() {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);


    async function onSubmit(data) {
        setLoading(true);
        console.log("Form data: ", data); // Debug: Check form data

        try {
            let loginData = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/auth/register`, {
                method: "POST",
                headers: {
                    "accept": "*/*",
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (loginData.ok) {
                // setSuccess(true)
                toast.success("تم إرسال رابط التفعيل للبريد الإلكتروني")
                loginData = await loginData.json();
                setTimeout(() => {
                    // navigate('/OTP');
                    navigate('/logIn');
                }, 5000);

                // Redirect to another page after successful submission
            } else if (loginData.status === 401 || loginData.status === 400) {
                setErrorMessage(toast.warn("هذا الجوال أو البريد الالكتروني مسجل من قبل"))

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", loginData.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
        } finally {
            setLoading(false);
            // navigate('/');
        }
    }


    return (
        <div className='dashboard d-flex flex-row'>
            <div className='dashboard-form container col-12 col-md-6 mx-auto my-auto px-4 px-md-5 text-start'>
                <h4 className='c-darkOrange'>أنشئ حساب</h4>
                <form className='d-flex flex-column w-75 mx-auto my-5 gap-2 text-start' onSubmit={handleSubmit(onSubmit)}>
                    <p className='text-info'>بالتسجيل في المنصة فأنت توافق على <a className='text-warning text-decoration-none' href='/terms'>سياسة الخصوصية</a></p>

                    <input className={`form-control ${errors.firstName && "border border-danger"}`} type="text" placeholder="الاسم الأول" {...register("firstName", { required: true, minLength: 3 })} />
                    {errors.firstName && <p className='errors-msg'>  أكبر من ٣ أحرف</p>}
                    <input className={`form-control ${errors.lastName && "border border-danger"}`} type="text" placeholder="اسم الأخير" {...register("lastName", { required: true, minLength: 3 })} />
                    {errors.lastName && <p className='errors-msg'>أكبر من ٣ أحرف</p>}

                    <input className={`form-control ${errors.email && "border border-danger"}`} type="email" placeholder="البريد الالكتروني" {...register("email", { required: true, pattern: emailValidation })} />
                    {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني صحيح</p>}

                    <input className={`form-control ${errors.mobilePhone && "border border-danger"}`} type="tel" placeholder="05xxxxxxxx" {...register("mobilePhone", { required: true, minLength: 10, pattern: mobileValidation })} />
                    {errors.mobilePhone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}

                    {/* <input className='form-control' type="Password" placeholder="كلمةالسر" {...register("password", { required: true, minLength: 8, maxLength: 16 })} />
                    {errors.password && <p className='errors-msg'>كلمة المرور تكون من ٨-١٠ أحرف</p>}

                    <input className='form-control' type="password" placeholder="إعادة كلمةالسر" {...register("passwordConfirm", {
                        required: true, validate: (val) => {
                            if (watch('password') !== val) { return "Your passwords do no match"; }
                        },
                    })} />
                    {errors.password !== errors.passwordConfirm && <p className='errors-msg'>كلمة المرور ليست متطابقة</p>} */}

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultChecked={true}
                            onChange={e => setValue("contactViaEmail", e.target.checked)}
                            id="contactViaEmail"
                        // {...register("contactViaEmail", { required: false })}
                        />
                        <label className="form-check-label" htmlFor="contactViaEmail">
                            أرسل لي تحديثات المنصة عبر البريد الالكتروني
                        </label>
                    </div>
                    <a className='text-secondary' href='/logIn'>لدي حساب بالفعل</a>

                    <div className="align-self-end">
                        <ReCaptchaHidden />
                    </div>

                    <input className='btn btn-light-blue text-white' type="submit" value="سجل" />
                </form>
                <SuccessErrorMsg
                    ToastContainer={ToastContainer}
                // errorMessage={errorMessage}
                // dataUpdate={success}
                // successMessage={"تم إرسال رابط التفعيل للبريد الإلكتروني"}
                />

                <div>


                </div>
            </div>
            <div className='d-none d-sm-block col-6 bg-light-blue'>
            </div>
        </div>

    )
}
