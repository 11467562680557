import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import handleLogout from '../../../utilities/handleFunctions/HandleLogout';


export default function HeaderAccount() {
    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        }
    }, [isLogIn]);

    return (
        <>
            <FontAwesomeIcon type='button' icon={faUser} className={`user-account text-white ${isLogIn ? "bg-dark-blue" : "bg-light-orange"}  dropdown-toggle`} id="userDropdown" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" />

            <ul className={`dropdown-menu dropdown-menu-end text-end ${isLogIn ? " bg-med-gray" : "bg-light-orange"} border-white`} aria-labelledby="userDropdown">
                {isLogIn ?
                    <>
                        <li>
                            <Link className="dropdown-item"
                                onClick={() => handleLogout("token", setIsLogIn)}
                                to="/">تسجيل الخروج</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="myAccount">حسابي</Link>
                        </li>
                    </>
                    :
                    <>
                        <li>
                            <Link className="dropdown-item" to="/logIn">تسجيل الدخول</Link>
                        </li>
                        <li>
                            <Link className="dropdown-item" to="/register">إنشاء حساب</Link>
                        </li>
                    </>
                }
            </ul>
        </>

    )
};



