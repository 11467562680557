import { useNavigate } from 'react-router-dom';
import PagesLinksData from "../../../data/PagesLinksData.json"
import ServiceDropdownList from "./ServiceDropdownList"
import "../navbar.css"

export default function NavPages({ isNavOpen }) {
    const navigate = useNavigate();


    return (
        <div className="d-flex flex-column flex-md-row align-items-center position-relative">
            {PagesLinksData.map((page, index) => (
                <div
                    key={index}
                    onClick={() => {
                        isNavOpen ? (navigate(page.pageLink)) : (page.pageNameEn !== "services" && navigate(page.pageLink)) // Navigate to the link
                    }}
                    className={`header-nav nav-link text-center ${page.addClass}`}>
                    {page.pageName}
                    <ServiceDropdownList />
                </div>
            ))}

        </div>
    );
}

