import { faXTwitter, faFacebookF, faYoutube, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const SocialData = [{
    icon: faXTwitter,
    link: "https://x.com/MnstMsar",
},
{
    icon: faFacebookF,
    link: "https://www.facebook.com/massarwebsite",
},
{
    icon: faYoutube,
    link: "https://www.youtube.com/channel/UCOSOawMHez1NUYVCXLxg2sQ",
},
{
    icon: faTiktok,
    link: "https://www.tiktok.com/@masar.platform",
},
{
    icon: faInstagram,
    link: "https://www.instagram.com/masar.platform/",
},
];



export default SocialData;