import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function InputPassword({ register, errorMessage }) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <div className="input-group input-group-sm">
                <input
                    className='form-control'
                    type={showPassword ? "text" : "password"}
                    placeholder="كلمةالسر"
                    {...register("password", { required: true })} />

                {/* Show or Hide password state */}
                <span className="input-group-text"
                    onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>

            </div>
            {errorMessage &&
                <p className='errors-msg bg-warning p-1 rounded' id='error-message'>{errorMessage}</p>}

        </>

    )
}