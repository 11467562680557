
const booksCategoryAr = ["الإسلامية والتراثية", "الإدارة والأعمال", "الأطفال", "التربية والتعليم", "القصة والرواية", "التراجم والسير", "قواميس وموسوعات", "القانون", "كتب الهندسة", "الهندسة والعمارة والتصميم", "العلوم والرياضيات", "التقنية والكمبيوتر", "الصحة العامة والتغذية", "الكتب الطبية", "تعلم اللغات", "الكتب المدرسية", "الأدب والشعر", "تطوير الذات", "العلوم الاجتماعية والسياسية", "التاريخ والجغرافيا", "العناية بالطفل", "الطبخ", "الهوايات والأشغال اليدوية", "المجلات", "الطبيعة والزراعة", "السفر والخرائط", "الكتب الأجنبية"]

const booksCategoryEn = ["Islamic and Heritage", "Management and Business", "Children", "Education and Teaching", "Fiction and Novels", "Biographies and Memoirs", "Dictionaries and Encyclopedias", "Law", "Engineering Books", "Architecture and Design", "Science and Mathematics", "Technology and Computers", "General Health and Nutrition", "Medical Books", "Language Learning", "School Books", "Literature and Poetry", "Self Development", "Social and Political Sciences", "History and Geography", "Child Care", "Cooking", "Hobbies and Crafts", "Magazines", "Nature and Agriculture", "Travel and Maps", "Foreign Books"]


const profileCategoryAr = ["الهوية البصرية", "المواقع والتطبيقات", "التسويق الرقمي", "الكتاب", "البروفايل", "الدعاية", "الافتات الإعلانية", "التغليف", "تصوير المنتجات", "تنظيم المعارض"]

const profileCategoryEn = ["Visual Identity", "Websites and Applications", "Digital Marketing", "Book", "Profile", "Promotional Packages", "Advertising Signs", "Packaging and Boxes", "Photography", "Exhibition Organization"]

const subServicesAr =
    ["تصميم موقع ويب", "الاستضافة / النطاق", "تصميم تطبيقات", "برمجة قواعد البيانات", "الباقة الأولى", "الباقة الثانية", "الباقة الثالثة", "الباقة الرابعة", "تصميم الغلاف", "التنسيق", "التدقيق اللغوي", "الطباعة", "النشر والتوزيع", "الترجمة", "إعداد المحتوى", "التصميم الداخلي", "الطباعة", "تصميم المناهج", "إعداد المناهج", "الخط العربي", "الشحن والنقل", "التصميم", "التصميم + الطباعة", "قسيمة الاستلام", "قسيمة الصرف", "بطاقة العمل", "الأوراق الرسمية", "الفواتير", "التصميم + التنفيذ", "التنفيذ", "التجديد", "النمذجة", "التصوير الفوتوغرافي", "فيديو قصير", "فيديو طويل", "معالجة الصور وتحريرها", "تصميم كشك 3d", "تنظيم الحدث", "معدات المعرض"]

const subServicesEn = ["Website Design", "Hosting / Domain", "Application Design", "Database Programming", "First Package", "Second Package", "Third Package", "Fourth Package", "Cover Design", "Formatting", "Proofreading", "Typesetting", "Publishing and Distribution", "Translation", "Content Preparation", "Internal Design", "Printing", "Curriculum Design", "Curriculum Preparation", "Arabic Calligraphy", "Shipping and Transport", "Design", "Design + Printing", "Receiving Voucher", "Disbursement Voucher", "Business Card", "Official Papers", "Invoices", "Design + Execution", "Execution", "Renovation", "Modeling", "Photography", "Short Video", "Long Video", "Image Processing and Editing", "Exhibition Booth Design 3D", "Event Organization", "Exhibition Equipment Setup"]


const servicesCategoryAr = ["الهوية البصرية", "المواقع والتطبيقات", "التسويق الرقمي", "الكتاب", "البروفايل", "الدعاية", "الافتات الإعلانية", "التغليف والعلب", "تصوير المنتجات", "تنظيم المعارض"]

const servicesCategoryEn = ["Visual Identity", "Websites and Applications", "Digital Marketing", "Book", "Profile", "Promotional Packages", "Advertising Signs", "Packaging and Boxes", "Photography", "Exhibition Organization"]


const statusListEn = ["Pending", "Rejected", "Approved"];
const statusListAr = ["معلق", "مرفوض", "معتمد"];

const payStatusEn = ["Pending", "Completed", "Failed"]
const payStatusAr = ["معلق", "مكتمل", "فشل"]


//Combining for categories

const allBooksCategories = [];

for (let i = 0; i < booksCategoryAr.length; i++) {
    allBooksCategories.push({
        ar: booksCategoryAr[i],
        en: booksCategoryEn[i]
    });
}

const allProfilesCategories = [];

for (let i = 0; i < profileCategoryAr.length; i++) {
    allProfilesCategories.push({
        ar: profileCategoryAr[i],
        en: profileCategoryEn[i]
    });
}

const allServicesCategories = [];

for (let i = 0; i < servicesCategoryAr.length; i++) {
    allServicesCategories.push({
        ar: servicesCategoryAr[i],
        en: servicesCategoryEn[i]
    });
}

const allSubServices = [];

for (let i = 0; i < subServicesAr.length; i++) {
    allSubServices.push({
        ar: subServicesAr[i],
        en: subServicesEn[i]
    });
}

const allStatusListItems = [];

for (let i = 0; i < statusListAr.length; i++) {
    allStatusListItems.push({
        ar: statusListAr[i],
        en: statusListEn[i]
    });
}

const allPaymentStatus = [];

for (let i = 0; i < payStatusAr.length; i++) {
    allPaymentStatus.push({
        ar: payStatusAr[i],
        en: payStatusEn[i]
    });
}



export { allBooksCategories, allProfilesCategories, allServicesCategories, allSubServices, allStatusListItems, allPaymentStatus };




