import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
// import NotificationBell from './NotificationBell';
import HeaderCart from './HeaderCart';
import HeaderAccount from './HeaderAccount';


export default function Account() {
    const [isLogIn, setIsLogIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            setIsLogIn(true)
        }
    }, [isLogIn]);

    return (

        <div className="user-icon d-flex flex-nowrap justify-content-center dropdown pt-2 pt-sm-0">
            {/* <NotificationBell /> */}
            <HeaderCart />
            <HeaderAccount />
        </div>


    )
};



