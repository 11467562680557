import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialData from '../../../data/SocialDataLinksData';

// for social Links section in footer
export default function SocialLinks() {
    return (
        <div className="social-icons">
            {SocialData.map((social, index) => (
                <Link key={index} to={social.link} target='blank'>
                    <FontAwesomeIcon icon={social.icon} />
                </Link>
            ))}
        </div>
    )
};

